import { useState } from 'react';
import classes from '../FileProcess.module.css';
import { useNavigate } from 'react-router-dom';
import api from '../../../Api';

const ZoomSet = (props) => {
    const navigate = useNavigate(null);

    const [clickZoom, setClickZoom] = useState(false);
    const handleClick = (e) => {
        props.setHandleZoom(e)
    }
    const handleZoomClick = () => {
        props.setIsAnnote(false);
        if (clickZoom) {
            setClickZoom(false);
            handleClick(0);
        }
        else {
            setClickZoom(true);
            handleClick(1);
        }
    }

    const handleLogout = async () => {
        try {
            const featherFileName = props.featherFileName;
            // Send a request to log out via IPC
            await api.post('/logout', {featherFileName:featherFileName});
            // Update state or perform any necessary actions after logging out
            props.setIsAnnote(false);
            props.setHandleZoom(0);
            props.setHandleZoom(0);
            props.setQuestionResults({});
            navigate('/');
        } catch (error) {
            console.error('Error logging out:', error);
            // Handle errors here
        }
    };

    return (
        <div className={classes.zoom}>
            <div className={classes.zoomButtons} style={{ color: "red" }}>
                <button onClick={() => {
                    props.setDefectDetail(null);
                    props.setDefectPos(null);
                    setClickZoom(false);
                    handleClick(-1)
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fillRule="currentColor" className="bi bi-zoom-out" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                        <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
                        <path fill-rule="evenodd" d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
                    </svg>
                </button>
                <p>Reset Zoom</p>
            </div>
            <div className={classes.zoomButtons}>
                <button onClick={handleZoomClick} style={props.handleZoom === 1 && (!props.isZoomed) ? { color: "black", backgroundColor: "#ffc929" } : {}}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-zoom-in" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                        <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
                        <path fill-rule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                </button>
                <p>Zoom</p>
            </div>
            <div className={classes.zoomButtons}>
                <button onClick={handleLogout} style={props.handleZoom === 2 && (!props.isZoomed) ? { color: "black", backgroundColor: "#ffc929" } : {}}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z" />
                        <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                    </svg>
                </button>
                <p>Logout</p>
            </div>
        </div>
    );
}

export default ZoomSet;
