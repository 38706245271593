import classes from '../FileProcess.module.css';
import { useEffect, useState } from 'react';
import { message } from 'antd';
import createPDF from './CreatePDF';
import api from '../../../Api';
import { LoadingOutlined } from '@ant-design/icons';
import { Space, Spin } from 'antd';

const ImportFile = ({ props }) => {
  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [hoveredFile, setHoveredFile] = useState(null);

  // Mock list of uploaded files for demonstration purposes
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    setUploadedFiles(props.userFiles);
    // console.log(props.userFiles);
  }, [props.userFiles]);


  const handleFileUpload = () => {
    props.setShow(true);
    setShow(false);
    props.setFile(file);
    if (file) {
      // You can now work with the uploaded Excel file here
      console.log("File selected:", file.name);
      // You can use libraries like SheetJS (XLSX) to process Excel files in JavaScript
    }
  };

  const handleExport = async () => {
    if (props.savedData === undefined) {
      message.error('No data to export!');
      return;
    }
    setIsExporting(true);
    await createPDF(props);
    setIsExporting(false);
  };

  const handleAnnote = () => {
    if (props.isAnnote) {
      props.setIsAnnote(0);
      return;
    }
    props.setIsAnnote(1);
  };

  // Function to convert base64 to Blob
  const base64toBlob = (base64Data, contentType) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const handleSave = async () => {
    const savedData = props.savedData;
    savedData.lwPos = props.lwPOS;
    if ((!savedData) || (!file)) {
      message.error('No data to save!');
      return;
    }
    try {
      setIsSaving(true);
      // Send the saveData object to the main process via IPC
      const res = await api.post('/save', savedData);
      const data = res.data;
      const blob = base64toBlob(data, 'application/octet-stream');

      const url = URL.createObjectURL(blob); // Create object URL from Blob
      const link = document.createElement('a'); // Create a link element
      link.href = url; // Set link's URL to the object URL
      link.download = 'your_file_name.feather'; // Specify default file name
      document.body.appendChild(link); // Append link to the body
      link.click(); // Simulate click to trigger download
      document.body.removeChild(link); // Remove link from the body
      setIsSaving(false);
    } catch (error) {
      console.log(error.message);
      setIsSaving(false);
      message.error(error.message);
    }
  };

  const handleClick = (type) => {
    props.setHandleZoom(false);
    if (type === -1) {
      setShow(!show);
      props.setIsAnnote(false);
    }
    if (type === 0) {
      setShow(!show);
      handleFileUpload();
      props.setIsAnnote(false);
    }
    if (type === 1) {
      handleSave();
      props.setIsAnnote(false);
    }
    if (type === 2) {
      props.setIsAnnote(false);
      handleExport();
    }
    if (type === 3) {
      handleAnnote();
    }
  };

  const handleFileSelect = (file,ind) => {
    setSelectedFile({ ...file, index: ind });
  };

  const handleProcessFile = () => {
    if (selectedFile) {
      setShow(!show);
      props.setFile(selectedFile);
      setShowFiles(false);
      setSelectedFile(null);
    }
  };

  const handleRowMouseEnter = (i) => {
    setHoveredFile(i);
  };

  const handleRowMouseLeave = () => {
    setHoveredFile(null);
  };

  return (
    <div className={classes.importAndSave}>
      <div className={classes.fileAccess}>
        <div className={classes.accessButtons}>
          <button onClick={() => handleClick(-1)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-folder2-open" viewBox="0 0 16 16">
              <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7z" />
            </svg>
          </button>
          <p>Open</p>
        </div>
        <div className={classes.accessButtons}>
          {isSaving ? (
            <button>
              <Space>
                <Spin indicator={<LoadingOutlined style={{ color: "#ffc929" }} spin />} size="large" />
              </Space>
            </button>
          ) : (
            <button onClick={() => handleClick(1)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-floppy" viewBox="0 0 16 16">
                <path d="M11 2H9v3h2z" />
                <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z" />
              </svg>
            </button>
          )}
          <p>Save</p>
        </div>
        <div className={classes.accessButtons}>
          {isExporting ? (
            <button>
              <Space>
                <Spin indicator={<LoadingOutlined style={{ color: "#ffc929" }} spin />} size="large" />
              </Space>
            </button>
          ) : (
            <button onClick={() => handleClick(2)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-cloud-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708z" />
                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
              </svg>
            </button>
          )}
          <p>Export</p>
        </div>
        {props.mode === 2 && (
          <div className={classes.accessButtons}>
            <button
              style={props.isAnnote ? { border: 'none', backgroundColor: '#ffc929', color: 'black' } : null}
              onClick={() => handleClick(3)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
              </svg>
            </button>
            <p>Annote</p>
          </div>
        )}
      </div>
      {show && (
        <div className={classes.chooseFile}>
          <div className={classes.uploadFile}>
            <div className={classes.importFeather}>
              <p>Import Feather File</p>
              <input type="file" accept=".feather" onChange={(e) => setFile(e.target.files[0])} />
              <button onClick={() => handleClick(0)}>Process</button>
            </div>
            <div className={classes.importExcel}>
              <p>Import Excel or CSV File</p>
              <input type="file" accept=".xls, .xlsx,.csv" onChange={(e) => setFile(e.target.files[0])} />
              <button onClick={() => handleClick(0)}>Convert to feather and Process</button>
            </div>
          </div>
          <span style={{color:"red",fontSize:"0.9rem"}}>
            *Processed files will be automatically uploaded in the server
          </span>
          <button className={classes.showFilesButton} onClick={() => setShowFiles(!showFiles)}>
            Show Uploaded Files
          </button>
          {showFiles && (
            <div className={classes.uploadedFilesWrapper}>
              <div className={classes.uploadedFiles}>
                <div className={classes.tableWrapper}>
                  <table>
                    <thead>
                      <tr>
                        <th>Last Date Processed</th>
                        <th>Name</th>
                        <th>Mode</th>
                      </tr>
                    </thead>
                    <tbody>
                      {uploadedFiles.map((file,i) => (
                        <tr
                          key={i}
                          onClick={() => handleFileSelect(file,i)}
                          onMouseEnter={() => handleRowMouseEnter(i)}
                          onMouseLeave={handleRowMouseLeave}
                          style={{
                            backgroundColor: i===hoveredFile ? '#ffc929' : (selectedFile?.index===i ? '#ffc929' : 'white'),
                            cursor: 'pointer',
                          }}
                        >
                          <td>{file.processedDate}</td>
                          <td>{file.originalFileName}</td>
                          <td>{file.mode}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <button
                  className={classes.processButton}
                  onClick={handleProcessFile}
                  disabled={!selectedFile}
                >
                  Process
                </button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                  onClick={() => {
                    setSelectedFile(null)
                    setShowFiles(false)
                  }}
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                </svg>
              </div>
            </div>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
            onClick={() => setShow(false)}
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </div>
      )}
    </div>
  );
};

export default ImportFile;
