import React from 'react';
import { Link } from 'react-router-dom';
import classes from './MainLanding.module.css'

const MainLanding = React.memo(() => {
    return (
        <div className={classes.mainLanding}>
            <h1 className={classes.title}>AI-enabled NDT software</h1>
            <h2 className={classes.subtitle}>for ultrasonic ToFD data evaluation</h2>
            <p className={classes.info}>
                iToFD is an intelligent AI-based ToFD data evaluation system for accurate weld defect identification and measurement. Revolutionizing NDT inspections, iToFD improves efficiency, accuracy, and cost-effectiveness for industries by automating the evaluation.
            </p>
            <Link to="/login" className={classes.loginButton}>Login to dashboard</Link>
        </div>
    );
});

export default MainLanding;