import classes from './ContactUs.module.css';
import { Link } from 'react-router-dom';
import appLogo from '../../assets/logo/logo.png';
// import logo from '../../assets/logo/image7.png';
import prof from '../../assets/team/prof.jpeg';
import abhi from '../../assets/team/abhi.jpeg';
import ananta from '../../assets/team/ananta.png';
import vishwa from '../../assets/team/vishwa.png';
import Sarvan from '../../assets/team/sarvan.JPG';
// import pdfFile from '../../assets/docs/Brochure_CoEAMT_2.pdf';
import manual from '../../assets/docs/manual.pdf';
import { useState } from 'react';


const ContactUs = () => {
    const [showPopup, setShowPopup] = useState(null);

    const openPopup = (opt) => {
        setShowPopup(opt);
    };

    const closePopup = () => {
        setShowPopup(false);
    };


    return (
        <div className={classes.contactus}>
            <div className={classes.appLogo}>
                <img src={appLogo} alt='appLogo' />
            </div>
            <div className={classes.navBar}>
                <Link to="/login"><button>Login</button></Link>
                <a href='https://www.coeamt.com/' target='blank'><button>About us</button></a>
                <button onClick={() => openPopup(2)}>Documentation</button>
                <button>Contact us</button>
            </div>
            <div className={classes.info}>
                <div className={classes.principle}>
                    <h1 className={classes.title}>Principal Investigator</h1>
                    <div className={classes.principleContent}>
                        <img src={prof} alt='prof' />
                        <div className={classes.principleInfo}>
                            <h1>Prof. Surjya K Pal</h1>
                            <p>Chairperson, Centre of Excellence in Advanced Manufacturing Technology</p>
                            <p>Mechanical Engineering IIT Kharagpur</p>
                            <p>Email: surjya.pal@icloud.com</p>
                        </div>
                    </div>
                </div>
                <div className={classes.team}>
                    <h1 className={classes.title}>Team</h1>
                    <div className={classes.teamInfo}>
                        <div className={classes.member}>
                            <img src={vishwa} alt='logo' />
                            <h1>Vishwanathan AK</h1>
                            <p>akvishwanathan1323@gmail.com</p>
                            <a href="https://www.linkedin.com/in/vishwanathan-ak-692316200/" className={classes.linked} target='blank'><i class="bi bi-linkedin"></i>LinkedIn</a>
                        </div>
                        <div className={classes.member}>
                            <img src={Sarvan} alt='logo' />
                            <h1>Sarvan Kumar Singh</h1>
                            <p>sarvan.tech17@gmail.com</p>
                            <a href="https://www.linkedin.com/in/sarvan-kumar-singh-622947226/" className={classes.linked} target='blank'><i class="bi bi-linkedin"></i>LinkedIn</a>
                        </div>
                        <div className={classes.member}>
                            <img src={ananta} alt='logo' />
                            <h1>Ananta Dutta</h1>
                            <p>duttaananta03@gmail.com</p>
                            <a href="https://www.linkedin.com/in/ananta-dutta-49674087/" className={classes.linked} target='blank'><i class="bi bi-linkedin"></i>LinkedIn</a>
                        </div>
                        <div className={classes.member}>
                            <img src={abhi} alt='logo' />
                            <h1>Abhishek</h1>
                            <p>avishekmukherjee100@gmail.com</p>
                            <a href="https://www.linkedin.com/in/avishek-mukherjee-b8a937153" className={classes.linked} target='blank'><i class="bi bi-linkedin"></i>LinkedIn</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={classes.landingBox}>
                <div className={classes.landingFooter}>
                    <span className={classes.centreName}>
                        <img src={logo} alt='logo' />
                        <div className={classes.developedByText}>
                            <p style={{ color: "white", paddingBottom: "1rem" }}><u>Developed by:</u></p>
                            <p>CENTRE OF EXCELLENCE IN ADVANCED MANUFATURING TECHNOLOGY</p>
                            <p>INDIAN INSTITUTE OF TECHNOLOGY KHARAGPUR</p>
                        </div>
                    </span>
                </div>
            </div> */}
            {showPopup === 2 && (
                <div className={classes.popup}>
                    <div className={classes.popupContent}>
                        <button onClick={closePopup}><i class="bi bi-x-circle"></i></button>
                        <embed src={manual} type="application/pdf" width="100%" height="500px" />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ContactUs;