import classes from './Modes.module.css';
import FileProcess from '../FileFunctionalities/FileProcess';
import Invert from '../InvertRowColumn/Invert';
import Navbar from '../Navbar/Navbar';
import { useEffect, useRef, useState } from 'react';
import ProcessedExcel from '../ProcessedExcel/ProcessedExcel';
import QuestionSet from '../QuestionSet/QuestionSet';

const Mode = (props) => {
  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);
  const [invert, setInvert] = useState(null);
  const [handleZoom, setHandleZoom] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [topBarHeight, setTopBarHeight] = useState(0);
  const [isAnnote, setIsAnnote] = useState(0);
  const [lwStraighten, setLWStraighten] = useState(false);
  const [lwPOS, setLWPOS] = useState(null);
  const [lwStraightened, setLWStraightened] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [clustInfo, setClustInfo] = useState([]);
  const [featherFileName, setFeatherFileName] = useState(null);
  const [image, setImage] = useState(null);
  const [rowCol, setRowCol] = useState([0, 0]);
  const [plotDimension, setPlotDimension] = useState([0, 0, 0, 0]);
  const [defectDetail, setDefectDetail] = useState(null);
  const [defectPos, setDefectPos] = useState(null);
  const [processScalingParam, setProcessScalingParam] = useState(false);
  const [scaleLoading, setScaleLoading] = useState(false);
  const [yAxisLabel, setYAxisLabel] = useState('px');
  const [yAxisData, setYAxisData] = useState([]);
  const [dataOriginalLength, setDataOriginalLength] = useState(0);
  const [annotationDimension, setAnnotationDimension] = useState([]);
  const [currentAnnotationDimension, setCurrentAnnotationDimension] = useState(null);
  const [showQuestions, setShowQuestions] = useState(true);
  const [shiftToLW,setShiftToLW]=useState(false);

  const [column, setColumn] = useState({
    1: null,
    2: null,
    3: null,
  })

  const [row, setRow] = useState({
    1: null,
    2: null,
    3: null,
  })

  useEffect(() => {
    if (props.savedData.data && (file === null)) {
      setFile(true);
      setShow(true);
    }
  }, [props.savedData.data, file, invert]); // Only run this effect when props.savedData.data changes


  const topBarRef = useRef(null);

  useEffect(() => {
    if (topBarRef.current) {
      setTopBarHeight(topBarRef.current.offsetHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topBarRef.current]);

  useEffect(() => {
    if (file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file?.type === 'text/csv') {
      setShow(true);
    }
    else if (file) {
      setShow(false)
    }
  }, [file]);
  
  useEffect(()=>{
    setRow({
      1: null,
      2: null,
      3: null,
    });
    setColumn({
      1: null,
      2: null,
      3: null,
    });
    setClustInfo([]);
    setDefectDetail(null);
    setDefectPos(null);
    setFeatherFileName(null);
    setImage(null);
    setInvert(null);
    setLWStraighten(false);
    setLWPOS(null);
    setLWStraightened(false);
    setDataFetched(false);
    setYAxisData([]);
    setYAxisLabel('px');
    setDataOriginalLength(0);
    setScaleLoading(false);
    setProcessScalingParam(false);
    setPlotDimension([0, 0, 0, 0]);
  },[file]);

  useEffect(()=>{
    setShiftToLW(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[lwPOS]);



  return (
    <div className={classes.manualMode}>
      <div className={classes.topBar} ref={topBarRef}>
        <Navbar />
        <FileProcess userFiles={props.userFiles} shiftToLW={shiftToLW} setShiftToLW={setShiftToLW} setQuestionResults={props.setQuestionResults} questionResults={props.questionResults} currentAnnotationDimension={currentAnnotationDimension} setCurrentAnnotationDimension={setCurrentAnnotationDimension} annotationDimension={annotationDimension} dataOriginalLength={dataOriginalLength} yAxisLabel={yAxisLabel} yAxisData={yAxisData} scaleLoading={scaleLoading} scalingParam={props.scalingParam} setScalingParam={props.setScalingParam} setProcessScalingParam={setProcessScalingParam} setDefectDetail={setDefectDetail} setDefectPos={setDefectPos} plotDimension={plotDimension} rowCol={rowCol} image={image} featherFileName={featherFileName} file={file} invert={invert} setFile={setFile} setShow={setShow} show={show} setHandleZoom={setHandleZoom} handleZoom={handleZoom} isZoomed={isZoomed} isAnnote={isAnnote} setIsAnnote={setIsAnnote} row={row} column={column} mode={props.mode} setLWStraighten={setLWStraighten} lwPOS={lwPOS} setLWPOS={setLWPOS} dataFetched={dataFetched} savedData={props.savedData} userId={props.userId} clustInfo={clustInfo} />
      </div>
      {showQuestions && <QuestionSet showQuestions={showQuestions} setShowQuestions={setShowQuestions} questionResults={props.questionResults} setQuestionResults={props.setQuestionResults} />}
      {!show ? file && <ProcessedExcel setUserFiles={props.setUserFiles} shiftToLW={shiftToLW} questionResults={props.questionResults} currentAnnotationDimension={currentAnnotationDimension} setCurrentAnnotationDimension={setCurrentAnnotationDimension} annotationDimension={annotationDimension} setAnnotationDimension={setAnnotationDimension} dataOriginalLength={dataOriginalLength} setDataOriginalLength={setDataOriginalLength} setYAxisData={setYAxisData} yAxisData={yAxisData} setYAxisLabel={setYAxisLabel}  yAxisLabel={yAxisLabel} setScaleLoading={setScaleLoading} setProcessScalingParam={setProcessScalingParam} scalingParam={props.scalingParam} setScalingParam={props.setScalingParam} processScalingParam={processScalingParam}  setDefectDetail={setDefectDetail} setDefectPos={setDefectPos} defectDetail={defectDetail} defectPos={defectPos} lwPOS={lwPOS} setPlotDimension={setPlotDimension} setRowCol={setRowCol} setImage={setImage} setFeatherFileName={setFeatherFileName} clustInfo={clustInfo} setClustInfo={setClustInfo} file={file} invert={invert} setInvert={setInvert} handleZoom={handleZoom} setHandleZoom={setHandleZoom} isZoomed={isZoomed} setIsZoomed={setIsZoomed} topBarHeight={window.innerHeight - topBarHeight} setRow={setRow} setColumn={setColumn} isAnnote={isAnnote} setIsAnnote={setIsAnnote} mode={props.mode} lwStraighten={lwStraighten} setLWStraighten={setLWStraighten} setLWPOS={setLWPOS} dataFetched={dataFetched} setDataFetched={setDataFetched} lwStraightened={lwStraightened} setLWStraightened={setLWStraightened} savedData={props.savedData} setSavedData={props.setSavedData} userId={props.userId} login={props.login} /> : file && <Invert file={file} setInvert={setInvert} setShow={setShow} savedData={props.savedData} />}
    </div>
  );
}

export default Mode;