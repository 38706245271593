import React from 'react';
import classes from './Disclaimer.module.css';

const Disclaimer = ({ onAgree }) => {
  const handleAgree = () => {
    localStorage.setItem('disclaimerAgreed', 'true');
    onAgree();
  };

  return (
    <div className={classes.disclaimerOverlay}>
      <div className={classes.disclaimerContent}>
        <div className={classes.exclamation}>!</div>
        <p>Our website will securely save the processed data for study purposes only.</p>
        <button onClick={handleAgree}>Agree</button>
      </div>
    </div>
  );
};

export default Disclaimer;
