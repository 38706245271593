import classes from './Navbar.module.css';
import logo from '../../assets/logo/logo.png';
import kgpLogo from '../../assets/logo/IIT-Kharagpur-01.svg';
import manual from '../../assets/docs/manual.pdf';
import { useState } from 'react';

const Navbar = () => {
    const [showPopup, setShowPopup] = useState(false);

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <div className={classes.navbar}>
            <ul>
                <li>
                    <li className={classes.selected} style={{ backgroundColor: "#ffc929" }}>
                        <img className={classes.kgpLogo} src={kgpLogo} alt='logo' />
                    </li>
                    <span>
                        Developed by&nbsp;
                        <span style={{ fontWeight: "700", textTransform: "uppercase" }}>
                            Centre of Excellence in Advanced Manufacturing Technology
                        </span>
                        <span style={{ fontWeight: "700", textTransform: "uppercase" }}>
                            IIT Kharagpur
                        </span>
                    </span>
                </li>
                <li className={classes.selected}>
                    <span style={{ fontWeight: "700" }} className={classes.logoIcon}>
                        <img className={classes.appLogo} src={logo} alt='logo' />
                    </span>
                    <span style={{ color: "#ffc929" }}>
                        v1.0
                    </span>
                    <i class="bi bi-question-circle-fill" onClick={openPopup}></i>
                    <p className={classes.help}>help</p>
                </li>
            </ul>
            {showPopup && (
                <div className={classes.popup}>
                    <div className={classes.popupContent}>
                        <button onClick={closePopup}><i class="bi bi-x-circle-fill"></i></button>
                        <embed src={manual} type="application/pdf" width="100%" height="500px" />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Navbar;