import React, { useEffect, useRef } from 'react';
import classes from './ContextMenu.module.css';

const ContextMenu = ({ position,setContextMenuPosition, onAddLine,setContextMenuSize,verticalLines,setVerticalLines,setHorizontalLines,setRow,setColumn,mode }) => {
  const menuRef = useRef(null);
  // console.log('mode in context menu: ',mode);
  const menuStyle = {
    position: 'fixed',
    top: position.y,
    left: position.x,
    border: '1px solid #ffc929',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
  };

  const handleAddLine = (opt) => {
    onAddLine(position.x, opt);
    setContextMenuPosition(null);
  };
  const handleRemoveLine=(opt)=>{
    setVerticalLines((prev)=>Object.fromEntries(
      Object.entries(prev).map(([key,value])=>{
        if(parseInt(key)===parseInt(opt)){
          return [key,null]
        }
        return [key,value];
      })
    ));

    setRow((prev)=>Object.fromEntries(
      Object.entries(prev).map(([key,value])=>{
        if(parseInt(key)===parseInt(opt)){
          return [key,null]
        }
        return [key,value];
      })
    ));

    setColumn((prev)=>Object.fromEntries(
      Object.entries(prev).map(([key,value])=>{
        if(parseInt(key)===parseInt(opt)){
          return [key,null]
        }
        return [key,value];
      })
    ));
    setHorizontalLines((prev)=>Object.fromEntries(
      Object.entries(prev).map(([key,value])=>{
        if(parseInt(key)===parseInt(opt)){
          return [key,null]
        }
        return [key,value];
      })
    ));
    setContextMenuPosition(null);
  }

  useEffect(()=>{
    setContextMenuSize({width:menuRef.current.offsetWidth,height:menuRef.current.offsetHeight})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[menuRef]);
  
  return (
    <div style={menuStyle} className={classes.menuStyle} ref={menuRef}>
      <ul>
        {verticalLines[1]?<li onClick={() => handleRemoveLine(1)}>Remove Data Cursor</li>:<li onClick={() => handleAddLine(1)}>Set Data Cursor</li>}
        {mode===2?verticalLines[2]?<li onClick={() => handleRemoveLine(2)}>Remove Reference Cursor</li>:<li onClick={() => handleAddLine(2)}>Set Reference Cursor</li>:null}
        {mode===2?verticalLines[3]&&mode===2?<li onClick={() => handleRemoveLine(3)}>Remove Measurement Cursor</li>:<li onClick={() => handleAddLine(3)}>Set Measurement Cursor</li>:null}
      </ul>
    </div>
  );
};

export default ContextMenu;
