import { useEffect, useRef, useState } from 'react';
import classes from './CustomYAxis.module.css';

const CustomYAxis = (props) => {
    const [lwPOS,setLWPOS]=useState(0);

    useEffect(()=>{
        if(props.shiftToLW){
            const lw=props.lwPOS?props.lwPOS:0;
            setLWPOS(lw);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.shiftToLW]);
    const row1=Math.max(props.row1-lwPOS,0);
    var start, end;
    if (props.yAxisData) {
        start = parseFloat(props.yAxisData[0]);
        end = parseFloat(props.yAxisData[1]);
    }

    const ticksCount = props.rowN - row1-lwPOS; // Adjust the number of ticks as needed
    const height = Math.ceil((window.innerHeight * 0.74) / ticksCount);

    const count = 5;
    const interval = Math.floor((props.rowN - row1-lwPOS) / count);
    // console.log(interval);
    const ticks = Array.from({ length: ticksCount }, (_, index) => index +row1 + 1);
    // console.log(ticks);
    const axisRef = useRef(null);
    const yaxis = useRef(null);

    useEffect(() => {
        if (axisRef.current) {
            // console.log(props);
            props.setPlotWidth(axisRef.current.offsetWidth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [axisRef.current]);

    useEffect(()=>{
        const axisHeight=(Math.max(lwPOS,props.row1)-props.row1+1)/(props.rowN-props.row1);
        if(yaxis.current){
            yaxis.current.style.top=`${axisHeight*100}%`;
            yaxis.current.style.height=`${(1-axisHeight)*100}%`;
        }
    },[lwPOS,props.row1,props.rowN]);

    const getTickValue = (tick) => {
        const value = parseFloat((((end - start) / props.dataOriginalLength) * tick) + start);
        return value.toFixed(2);
    }

    return (
        <div className={classes.completeYAxis} ref={axisRef}>
            <div className={classes.axis} ref={yaxis}>
                <p className={classes.axisDataType}>{props.yAxisLabel}</p>
                {ticks.map((tick, index) => {
                    if (((tick - row1 - 1 ) % interval === 0)) {
                        return (
                            (props.type === 1 ?
                                <div key={tick} className={classes.tick} style={{ height: `${height}px` }}>
                                    <div className={classes.dash}>
                                        <div className={classes.bigDash}></div>
                                    </div>
                                    <p style={{ transform: 'rotate(90deg)', transformOrigin: 'left', marginTop: `${index === 0 ? "50%" : (index === (parseInt(ticksCount / 5)) * 5) ? "-120%" : null}` }}>{props.yAxisLabel === "mm" ? getTickValue(tick) : tick}</p>
                                </div>
                                :
                                <div key={tick} className={classes.tick} style={{ height: `${height}px` }}>
                                    <p style={{ transform: 'rotate(-90deg)', transformOrigin: 'right', marginTop: `${index === 0 ? "50%" : (index === (parseInt(ticksCount / 5)) * 5) ? "-120%" : null}` }}>{props.yAxisLabel === "mm" ? getTickValue(tick) : tick}</p>
                                    <div className={classes.dash} style={{ justifyContent: "flex-end" }}>
                                        <div className={classes.bigDash}></div>
                                    </div>
                                </div>
                            )
                        );
                    }
                    else if (((tick - row1 - 1 ) % (Math.floor(interval / 4)) === 0)) {
                        return (
                            (props.type === 1 ?
                                <div key={tick} className={classes.tick} style={{ height: `${height}px` }}>
                                    <div className={classes.dash}>
                                        <div className={classes.smallDash}></div>
                                    </div>
                                </div>
                                :
                                <div key={tick} className={classes.tick} style={{ height: `${height}px` }}>
                                    <div className={classes.dash} style={{ justifyContent: "flex-end" }}>
                                        <div className={classes.smallDash}></div>
                                    </div>
                                </div>
                            )
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    );
}

export default CustomYAxis;
