import { useEffect, useRef, useState } from 'react';
import classes from './FileProcess.module.css';
import ImportFile from './Functionalities/ImportFile';
import ZoomSet from './Functionalities/ZoomSet';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const FileProcess = (props) => {
    const fileRef = useRef(null);
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const [depth, setDepth] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showProcessScalingButton, setShowProcessScalingButton] = useState(false);
    const [isHovered, setIsHovered] = useState(0);
    const [shiftedToLW, setShiftedToLW] = useState(false);
    const [lwPOS,setLWPOS]=useState(props.lwPOS);

    useEffect(() => {
        if (props.scalingParam?.S && props.scalingParam?.H && props.scalingParam?.C && props.file) {
            setShowProcessScalingButton(true);
        }
    }, [props.scalingParam, props.file])

    const handleIndexChange = (event) => {
        const selectedIndex = parseInt(event.target.value - 1);
        setSelectedItem(selectedIndex);
    };

    useEffect(() => {
        props.setCurrentAnnotationDimension({ width: width, height: height, depth: depth });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, depth])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (fileRef.current && !fileRef.current.contains(event.target)) {
                // Clicked outside of the dropdown, so close it
                const selectElement = document.querySelector(`.${classes.dropdown}`);
                if (selectElement) {
                    selectElement.size = 0;
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    },);



    const handleLWPOS = () => {
        props.setLWPOS(props.row['3'] !== null ? props.row['3'] + 1 : null);
    }
    const handleMeasure = (opt) => {
        if (opt === 1) {
            setWidth(Math.abs(props.column['2'] - props.column['3']));
        }
        else if (opt === 2) {
            if (props.yAxisLabel === "mm") {
                const value = Math.abs(getNewYPos(props.row['2'] + 1) - getNewYPos(props.row['3'] + 1));
                setHeight(value.toFixed(2));
            }
            else {
                setHeight(Math.abs(props.row['2'] - props.row['3']));
            }
        }
        else {
            if (props.yAxisLabel === "mm") {
                const value = Math.abs(getNewYPos(props.lwPOS) - getNewYPos(props.row['3'] + 1));
                setDepth(value.toFixed(2));
            }
            else {
                setDepth(Math.abs(props.lwPOS - props.row['3'] - 1));
            }
        }
    }

    useEffect(() => {
        if (props.yAxisLabel === "mm") {
            setHeight(height ? getNewYPos(height) : null);
            setDepth(depth ? getNewYPos(depth) : null);
            setWidth(width ? getNewYPos(width) : null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.yAxisLabel])

    const handleMouseOver = (num) => {
        setIsHovered(num);
    };

    const handleMouseOut = () => {
        setIsHovered(null);
    };

    const getNewYPos = (pos) => {
        const start = parseFloat(props.yAxisData[0]);
        const end = parseFloat(props.yAxisData[1]);
        const value = parseFloat((((end - start) / props.dataOriginalLength) * pos) + start);
        return value.toFixed(2);
    }

    useEffect(() => {
        setWidth(null);
        setHeight(null);
        setDepth(null);
    }, [props.file]);

    return (
        <div className={classes.process}>
            <ImportFile props={props} />
            {props.mode === 2 ?
                <div className={classes.functionalities}>
                    <div className={classes.dataBox}>
                        <p className={classes.title}>RC Pos</p>
                        <span className={classes.posData}>
                            <p>X</p>
                            <p className={classes.posVal}>{props.column['2'] !== null ? props.column['2'] + 1 + ' ' + props.yAxisLabel : null}</p>
                        </span>
                        <span className={classes.posData}>
                            <p>Y</p>
                            <p className={classes.posVal}>{(props.row['2'] !== null ? shiftedToLW?(props.yAxisLabel === "mm" ? Math.max(getNewYPos(props.row['2'] + 1)-getNewYPos(lwPOS),getNewYPos(1)).toFixed(2) : Math.max(props.row['2'] + 1-lwPOS,1)) + ' ' + props.yAxisLabel:(props.yAxisLabel === "mm" ? getNewYPos(props.row['2'] + 1) : props.row['2'] + 1) + ' ' + props.yAxisLabel : null)}</p>
                        </span>
                    </div>
                    <div className={classes.dataBox}>
                        <p className={classes.title}>MC Pos</p>
                        <span className={classes.posData}>
                            <p>X</p>
                            <p className={classes.posVal}>{props.column['3'] !== null ? props.column['3'] + 1 + ' ' + props.yAxisLabel : null}</p>
                        </span>
                        <span className={classes.posData}>
                            <p>Y</p>
                            <p className={classes.posVal}>{(props.row['3'] !== null ? shiftedToLW?(props.yAxisLabel === "mm" ? Math.max(getNewYPos(props.row['3'] + 1)-getNewYPos(lwPOS),getNewYPos(1)).toFixed(2) : Math.max(props.row['3'] + 1-lwPOS,1)) + ' ' + props.yAxisLabel:(props.yAxisLabel === "mm" ? getNewYPos(props.row['3'] + 1) : props.row['3'] + 1) + ' ' + props.yAxisLabel : null)}</p>
                        </span>
                    </div>
                    <div className={classes.dataBox}>
                        <p className={classes.title}>Measurements</p>
                        <span className={classes.measuredData}>
                            <p>Length</p>
                            {width && <p className={classes.posVal}>{width + ' ' + props.yAxisLabel}</p>}
                            {props.column['2'] != null && props.column['3'] != null ? <button onClick={() => handleMeasure(1)} className={classes.measure}>Calc</button> : <button className={classes.disableMeasure} disabled>Calc</button>}
                        </span>
                        <span className={classes.measuredData}>
                            <p>Height</p>
                            {height && <p className={classes.posVal}>{height + ' ' + props.yAxisLabel}</p>}
                            {props.row['2'] != null && props.row['3'] != null ? <button onClick={() => handleMeasure(2)} className={classes.measure}>Calc</button> : <button className={classes.disableMeasure} disabled>Calc</button>}
                        </span>
                        <span className={classes.measuredData}>
                            <p>Depth</p>
                            {depth && <p className={classes.posVal}>{depth + ' ' + props.yAxisLabel}</p>}
                            {props.lwPOS != null && props.row['3'] != null ? <button onClick={() => handleMeasure(3)} className={classes.measure}>Calc</button> : <button className={classes.disableMeasure} disabled>Calc</button>}
                        </span>
                    </div>
                    <div className={classes.functionalities}>
                        <div className={classes.dataBox}>
                            <p className={classes.title}>LW Pos</p>
                            <span className={classes.posData}>
                                <p>Y</p>
                                {props.lwPOS && <p className={classes.posVal}>{(props.shiftToLW ? (props.yAxisLabel === "mm" ? getNewYPos(1) : 1) : (props.yAxisLabel === "mm" ? getNewYPos(props.lwPOS) : props.lwPOS)) + ' ' + props.yAxisLabel}</p>}
                            </span>
                            <button onClick={() => {
                                setLWPOS(props.lwPOS);
                                props.setShiftToLW(true);
                                setShiftedToLW(true);
                            }} disabled={(!props.lwPOS) ? true : props.shiftToLW}>Set LW to 0</button>
                            <button onClick={handleLWPOS}>Add</button>
                            <button onClick={() => props.setLWStraighten(true)} disabled={!props.dataFetched}>Straighten</button>
                        </div>
                    </div>
                </div> :
                <div className={classes.functionalities}>
                    <div className={classes.dataBox}>
                        <p className={classes.title}>Select Annotation</p>
                        <span className={classes.posData}>
                            <select
                                className={classes.dropdown}
                                ref={fileRef}
                                onMouseDown={(e) => { if (props.clustInfo?.length > 6) { e.target.size = 6; } }}
                                onChange={(e) => {
                                    e.target.size = 0
                                    handleIndexChange(e);
                                }}
                                onBlur={(e) => e.target.size = 0}
                            >
                                {props.clustInfo?.map((item, index) => (
                                    <option key={index} value={index + 1}>{index + 1}</option>
                                ))}
                            </select>
                        </span>
                    </div>
                    {props.clustInfo?.length > 0 && <div className={classes.dataBox}>
                        <p className={classes.title}>Measurements</p>
                        <span className={classes.measuredData}>
                            <p>Length</p>
                            {selectedItem && <p className={classes.posVal}>{props.clustInfo[selectedItem][2] + ' ' + props.yAxisLabel}</p>}
                        </span>
                        <span className={classes.measuredData}>
                            <p>Height</p>
                            {selectedItem && <p className={classes.posVal}>{(props.yAxisLabel === "mm" ? getNewYPos(props.clustInfo[selectedItem][3]) : props.clustInfo[selectedItem][3]) + ' ' + props.yAxisLabel}</p>}
                        </span>
                        <span className={classes.measuredData}>
                            <p>Depth</p>
                            {selectedItem && <p className={classes.posVal}>{(props.yAxisLabel === "mm" ? getNewYPos(props.clustInfo[selectedItem][1]) : props.clustInfo[selectedItem][1]) + ' ' + props.yAxisLabel}</p>}
                        </span>
                    </div>}
                    <div className={classes.dataBox}>
                        <p className={classes.title}>LW Pos</p>
                        <span className={classes.posData}>
                            <p>Y</p>
                            {props.lwPOS && <p className={classes.posVal}>{(props.shiftToLW ? (props.yAxisLabel === "mm" ? getNewYPos(1) : 1) : (props.yAxisLabel === "mm" ? getNewYPos(props.lwPOS) : props.lwPOS)) + ' ' + props.yAxisLabel}</p>}
                        </span>
                        <button onClick={() => {
                            props.setShiftToLW(true);
                            setShiftedToLW(true);
                        }} disabled={(!props.lwPOS) ? true : props.shiftToLW}>Set LW to 0</button>
                    </div>
                </div>
            }
            <div className={classes.scaleParamDataBox}>
                <p className={classes.title}>Scaling Parameters</p>
                <span className={classes.scaleData} onMouseOver={() => handleMouseOver(1)} onMouseOut={handleMouseOut}>
                    <p>S</p>
                    <input className={classes.scaleVal} type='text' value={props.scalingParam?.S} onChange={(e) => props.setScalingParam((prev) => {
                        return { ...prev, S: e.target.value }
                    })} />
                    {isHovered === 1 && <span className={classes.scalingParamDetail}>probe-to-probe distance (mm)</span>}
                </span>
                <span className={classes.scaleData} onMouseOver={() => handleMouseOver(2)} onMouseOut={handleMouseOut}>
                    <p>H</p>
                    <input className={classes.scaleVal} type='text' value={props.scalingParam?.H} onChange={(e) => props.setScalingParam((prev) => {
                        return { ...prev, H: e.target.value }
                    })} />
                    {isHovered === 2 && <span className={classes.scalingParamDetail}>Thickness of the workpiece (mm)</span>}
                </span>
                <span className={classes.scaleData} onMouseOver={() => handleMouseOver(3)} onMouseOut={handleMouseOut}>
                    <p>C</p>
                    <input className={classes.scaleVal} type='text' value={props.scalingParam?.C} onChange={(e) => props.setScalingParam((prev) => {
                        return { ...prev, C: e.target.value }
                    })} />
                    {isHovered === 3 && <span className={classes.scalingParamDetail}>Wave propogation speed (m/s)</span>}
                </span>
                <button className={classes.scaleProcessButton} onClick={() => props.setProcessScalingParam(true)} disabled={!showProcessScalingButton}>{props.scaleLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 12, color: "#201e1e" }} spin />} /> : "Process"}</button>
            </div>
            <ZoomSet setQuestionResults={props.setQuestionResults} setDefectPos={props.setDefectPos} setDefectDetail={props.setDefectDetail} featherFileName={props.featherFileName} setHandleZoom={props.setHandleZoom} handleZoom={props.handleZoom} isZoomed={props.isZoomed} setIsAnnote={props.setIsAnnote} />
        </div>
    );
}

export default FileProcess;