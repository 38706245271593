import { jsPDF } from "jspdf";
import firstPage from '../../../assets/docs/firstPage.jpeg';
import nextPage from '../../../assets/docs/nextPage.jpeg';
import lastPage from '../../../assets/docs/lastPage.jpeg';
import 'jspdf-autotable';

const compressBase64Image = async (base64Image, maxWidth, maxHeight) => {

    return new Promise(resolve => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const scaleFactor = Math.min(maxWidth / img.width, maxHeight / img.height) * 15;
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            resolve(canvas.toDataURL('image/jpeg'));
        };
        img.src = `data:image/png;base64,${base64Image}`;
    });
}


const createPDF = async (data) => {
    const getNewYPos=(pos)=>{
        const start=parseFloat(data.yAxisData[0]);
        const end=parseFloat(data.yAxisData[1]);
        const value=parseFloat((((end-start)/data.dataOriginalLength)*pos)+start);
        return value.toFixed(2);
    }
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Get the width and height of the document
    const docWidth = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.getHeight();

    // Add image to the first page and occupy entire page
    doc.addImage(firstPage, "jpeg", 0, 0, docWidth, docHeight); // Set image dimensions to match page size


    // Calculate dimensions and position to center the image horizontally with 10% margins on each side
    const imageWidth = docWidth * 0.85; // 80% of page width
    const imageX = ((docWidth - imageWidth) / 2)+3; // Centering horizontally
    const imageY = 125; // Adjust vertically as needed
    // Compress and add image to the first page
    const compressedImage = await compressBase64Image(data.image, imageWidth, imageWidth*0.8 );
    // Add image to the first page
    doc.addImage(compressedImage, "JPEG", imageX, imageY, imageWidth, imageWidth*0.8 ); // Adjust dimensions based on aspect ratio


    doc.setFontSize(9);
    doc.setFont("helvetica", "bold"); // Set font to bold
    // Add file name at the top left of the page
    const fileName = data.file?.name ;

    // Set font color
    doc.setTextColor(0, 0, 0); // Set font color to red (RGB: 255, 0, 0)
    doc.text(46, 113, fileName);

    const unit='('+data.yAxisLabel+')';
    doc.text(96, 114, unit);

    const lwPOS=data.yAxisLabel==="mm"?getNewYPos(data.lwPOS):data.lwPOS ;
    doc.text(112, 114, String(lwPOS));


    const name=data.questionResults?.name ;
    doc.text(46, 66, name);

    const id=data.questionResults?.userId ;
    doc.text(112, 66 , id);

    // Calculate width of the timestamp
    const timestamp = new Date().toLocaleDateString();
    // Add timestamp at the top right of the page
    doc.text(175, 66, timestamp);

    const jobNumber=data.questionResults?.jobNumber ;
    doc.text(48, 75, (jobNumber));

    const jobMaterial=data.questionResults?.jobMaterial || "Unknown";
    doc.text(112, 75, jobMaterial);

    const mode=data.questionResults?.mode ;
    doc.text(175, 75, mode);

    let S=String(data.scalingParam?.S);
    let H=String(data.scalingParam?.H);
    let C=String(data.scalingParam?.C);
    doc.text(46, 95, S);
    doc.text(112, 95, H);
    doc.text(175, 95, C);



    const annotationRectangles = data.savedData?.annotationRectanglesOriginal || [];
    annotationRectangles.map(rectangle => {
        // Add a rectangle with a name at a certain coordinate
        const rectX = imageX + (imageWidth*(parseFloat(Math.min(rectangle.startX, rectangle.startX + rectangle.width) - (data.plotDimension[2]))/data.plotDimension[0])); // X coordinate of the rectangle
        const rectY = imageY + (imageWidth*0.7*(parseFloat(Math.min(rectangle.startY, rectangle.startY + rectangle.height) - (data.plotDimension[3]))/data.plotDimension[1])); // Y coordinate of the rectangle
        const rectWidth = (imageWidth/data.plotDimension[0])*Math.abs(rectangle.width); // Width of the rectangle
        const rectHeight = (imageWidth*0.7/data.plotDimension[1])*Math.abs(rectangle.height); // Height of the rectangle
        doc.rect(rectX, rectY, rectWidth, rectHeight); // Draw the rectangle
        doc.setTextColor(255, 0, 0);
        doc.setFont('helvetica', 'normal');
        const rectName = rectangle.name; // Name of the rectangle
        doc.text(rectX, rectY , rectName); // Add text inside the rectangle
        return rectangle;
    });

    const clustInfo = data.clustInfo || [];
    const tableData = parseInt(data.mode) === 2 ? data.annotationDimension?.map(rectangle => {
        const height = rectangle.height
        const width = rectangle.width
        const depth = rectangle.depth;

        return [rectangle.name, (height), (width), (depth)];
    }) :
        clustInfo.map((item) => {
            const name = item[0];
            const height = data.yAxisLabel==="mm"?getNewYPos(item[3]):item[3];
            const width = item[2];
            const depth = data.yAxisLabel==="mm"?getNewYPos(item[1]):item[1];
            return [name, height, width, depth];
        });

    
    let remainingData = tableData; // Initialize remaining data to the entire table data

    const totalDefects=tableData?.length||0;
    doc.text(175, 113, String(totalDefects));



    doc.setFont("helvetica", "bold");

    let yPosition=79;
    let gap=7;
    // Loop until all data is processed
    while (remainingData&&remainingData.length > 0) {
        doc.addPage();

        doc.setFontSize(10);
        const numRowsPerPage = 25; // Maximum number of rows that can fit on a page
        if (numRowsPerPage >= remainingData.length) {
            doc.addImage(lastPage, "jpeg", 0, 0, docWidth, docHeight); // Set image dimensions to match page size
            doc.text(84, 70, unit);
            doc.text(132, 70, unit);
            doc.text(184, 70, unit);
            for(let i=0;i<remainingData.length;i++){
                doc.text(35, yPosition, String(remainingData[i][0]));
                doc.text(84, yPosition, String(remainingData[i][1]));
                doc.text(130, yPosition, String(remainingData[i][2]));
                doc.text(172, yPosition, String(remainingData[i][3]));
                if(((i+1)%2===0)){
                    yPosition+=1;
                }
                else if(i%3===0&&i>5&&i<12){
                    yPosition+=2;
                }
                yPosition+=gap;
            }
            remainingData = []; // Clear remaining data as all of it has been added
        } else {
            doc.addImage(nextPage, "jpeg", 0, 0, docWidth, docHeight); // Set image dimensions to match page size
            doc.text(84, 70, unit);
            doc.text(132, 70, unit);
            doc.text(184, 70, unit);
            for(let i=0;i<25;i++){
                doc.text(35, yPosition, String(remainingData[i][0]));
                doc.text(84, yPosition, String(remainingData[i][1]));
                doc.text(130, yPosition, String(remainingData[i][2]));
                doc.text(172, yPosition, String(remainingData[i][3]));
                if(((i+1)%2===0)){
                    yPosition+=1;
                }
                else if(i%3===0&&i>5&&i<12){
                    yPosition+=2;
                }
                yPosition+=gap;
            }
            remainingData = remainingData.slice(numRowsPerPage); // Remove added data from the remaining data
        }
        yPosition = 79; // Reset yPosition for the new page
    }


    // Save the PDF
    doc.save("Report.pdf");
}

export default createPDF;