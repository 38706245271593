import { useEffect, useRef, useState } from 'react';
import classes from './DefectDetail.module.css';

const DefectDetail = ({ defectPos, defectDetail, plotLeft, plotTop, plotWidth, plotHeight,yAxisData,dataOriginalLength,yAxisLabel }) => {

    const getNewYPos=(pos)=>{
        const start=parseFloat(yAxisData[0]);
        const end=parseFloat(yAxisData[1]);
        const value=parseFloat((((end-start)/dataOriginalLength)*pos)+start);
        return value.toFixed(2);
    }

    const ref=useRef(null);
    const [defect, setDefect] = useState(null);

    useEffect(()=>{
        let posX,posY;
        if(defectPos.y-plotTop+ref.current?.offsetHeight>plotHeight){
            posY=plotHeight-ref.current?.offsetHeight;
        }
        else{
            posY=defectPos.y-plotTop;
        }
        if(defectPos.x-plotLeft+ref.current?.offsetWidth>plotWidth){
            posX=plotWidth-ref.current?.offsetWidth;
        }
        else{
            posX=defectPos.x-plotLeft;
        }
        setDefect({x:posY, y:posX});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[defectPos, plotLeft, plotTop, plotWidth, plotHeight,defectDetail])

    

    return (
        <div style={{ position: "absolute", top: defect?.x, left: defect?.y }} className={classes.defectBox} ref={ref}>
            <p className={classes.title}>Measurements</p>
            <span className={classes.measuredData}>
                <p>Defect Number</p>
                {defectDetail && <p className={classes.posVal}>{defectDetail[0]}</p>}
            </span>
            <span className={classes.measuredData}>
                <p>Length</p>
                {defectDetail && <p className={classes.posVal}>{defectDetail[2]+" "+yAxisLabel}</p>}
            </span>
            <span className={classes.measuredData}>
                <p>Height</p>
                {defectDetail && <p className={classes.posVal}>{(yAxisLabel==="mm"?getNewYPos(defectDetail[3]):defectDetail[3])+" "+yAxisLabel}</p>}
            </span>
            <span className={classes.measuredData}>
                <p>Depth</p>
                {defectDetail && <p className={classes.posVal}>{(yAxisLabel==="mm"?getNewYPos(defectDetail[1]):defectDetail[1])+" "+yAxisLabel}</p>}
            </span>
        </div>
    );
}

export default DefectDetail;