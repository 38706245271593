import { useState } from 'react';
import './App.css';
import Mode from './Components/Modes/Modes.js';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'; // Use HashRouter here
import LandingPage from './Components/LandingPage/LandingPage.js';
import Login from './Components/LandingPage/Login/Login.js';
import SignUp from './Components/LandingPage/SignUp/SignUp.js';
import MainLanding from './Components/LandingPage/MainLanding/MainLanding.js';
import ContactUs from './Components/ContactUs/ContactUs.js';

function App() {
  const [mode, setMode] = useState(0);
  const [savedData, setSavedData] = useState({});
  const [userId, setUserId] = useState(null);
  const [login, setLogin] = useState(false);
  const [scalingParam, setScalingParam] = useState(null);
  const [questionResults, setQuestionResults] = useState({});
  const [userFiles,setUserFiles]=useState([]);
  const props = { setMode, setSavedData, setUserId, setLogin,setScalingParam,setQuestionResults,userFiles,setUserFiles };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="*" element={<LandingPage props={props} />}>
            <Route path='*' element={<MainLanding />} />
            <Route path='login' element={<Login props={props} />} />
            <Route path='signup' element={<SignUp />} />
          </Route>
          <Route path="/contactus" element={<ContactUs props={props} />} />
          <Route path="/mode" element={<Mode userFiles={userFiles} setUserFiles={setUserFiles} questionResults={questionResults} setQuestionResults={setQuestionResults} scalingParam={scalingParam} setScalingParam={setScalingParam} mode={mode} savedData={savedData} setSavedData={setSavedData} userId={userId} login={login} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
