/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ConditionalPlot from "../ConditionalPlotting/ConditionalPlot";
import DataGraph from "../Graph/DataGraph";
import classes from './ProcessedExcel.module.css';
import CustomYAxis from "../CustomScales/CustomYAxis";
import CustomXAxisImage from "../CustomScales/CustomXAxisImage/CustomXAxisImage";
import CustomXAxisPlot from "../CustomScales/CustomXAxisPlot/CustomXAxisPlot";
import Loader from "react-js-loader";

const ProcessedExcel = ({setUserFiles,shiftToLW,questionResults,currentAnnotationDimension,setCurrentAnnotationDimension,annotationDimension,setAnnotationDimension,dataOriginalLength,setDataOriginalLength,yAxisLabel,setYAxisLabel,yAxisData,setYAxisData,setScaleLoading,setProcessScalingParam,processScalingParam, setScalingParam, scalingParam, setDefectPos, setDefectDetail, defectPos, defectDetail, lwPOS,setPlotDimension, setRowCol, setImage,setFeatherFileName, file, invert, handleZoom, isZoomed, setIsZoomed, topBarHeight, setRow, setColumn, isAnnote, setIsAnnote, setHandleZoom, mode, lwStraighten, setLWStraighten, setLWPOS, lwStraightened, setLWStraightened, setDataFetched, savedData, setSavedData, userId, setInvert, login, clustInfo, setClustInfo }) => {
    // console.log('mode: ',mode);
    const [data, setData] = useState(null);
    const [row1, setRow1] = useState(0);
    const [rowN, setRowN] = useState(0);
    const [column1, setColumn1] = useState(0);
    const [columnN, setColumnN] = useState(0);
    const [plotWidth, setPlotWidth] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    useEffect(()=>{
        if(isLoading){
            setData(null);
        }
    },[isLoading])

    return (
        <div className={classes.belowWindow} style={{ height: `${topBarHeight}px` }}>
            {isLoading &&
                <div className={classes.loader} style={{ height: `${topBarHeight}px` }}>
                    <Loader type="spinner-default" bgColor={"black"} color={"black"} title={(((file?.type) === excelFileType)&&(!lwStraighten)) ? 'CONVERTING' : 'LOADING'} size={100} />
                </div>
            }
            <div className={classes.completeWindow} style={{ height: `${topBarHeight - 32}px` }}>
                {data&&(!isLoading) && <CustomYAxis shiftToLW={shiftToLW} lwPOS={lwPOS} dataOriginalLength={dataOriginalLength} data={data} yAxisData={yAxisData} yAxisLabel={yAxisLabel} row1={row1} rowN={rowN} setPlotWidth={setPlotWidth} type={2} />}
                <div className={classes.plotWindow} style={{ width: `${window.innerWidth - (2 * plotWidth)}px` }}>
                    {data&&(!isLoading) && <DataGraph data={data} invert={invert} row1={row1} rowN={rowN} topBarHeight={topBarHeight - 32} mode={mode} lwStraighten={lwStraighten} lwStraightened={lwStraightened} />}
                    <ConditionalPlot setUserFiles={setUserFiles} questionResults={questionResults} dataOriginalLength={dataOriginalLength} currentAnnotationDimension={currentAnnotationDimension} setCurrentAnnotationDimension={setCurrentAnnotationDimension} annotationDimension={annotationDimension} setAnnotationDimension={setAnnotationDimension} yAxisData={yAxisData} yAxisLabel={yAxisLabel} setDataOriginalLength={setDataOriginalLength} setScaleLoading={setScaleLoading} setYAxisData={setYAxisData} setYAxisLabel={setYAxisLabel} setProcessScalingParam={setProcessScalingParam} scalingParam={scalingParam} processScalingParam={processScalingParam} setScalingParam={setScalingParam}  setDefectDetail={setDefectDetail} setDefectPos={setDefectPos} defectDetail={defectDetail} defectPos={defectPos} lwPOS={lwPOS} setRowCol={setRowCol} setPlotDimension={setPlotDimension} setImage={setImage} setFeatherFileName={setFeatherFileName} clustInfo={clustInfo} setClustInfo={setClustInfo} file={file} setData={setData} invert={invert} handleZoom={handleZoom} setHandleZoom={setHandleZoom} setIsZoomed={setIsZoomed} isZoomed={isZoomed} setRow1={setRow1} setRowN={setRowN} setColumn1={setColumn1} setColumnN={setColumnN} plotWidth={window.innerWidth - (2 * plotWidth)} setRow={setRow} setColumn={setColumn} isAnnote={isAnnote} setIsAnnote={setIsAnnote} mode={mode} lwStraighten={lwStraighten} setLWStraighten={setLWStraighten} setLWPOS={setLWPOS} lwStraightened={lwStraightened} setLWStraightened={setLWStraightened} setDataFetched={setDataFetched} savedData={savedData} setSavedData={setSavedData} userId={userId} setInvert={setInvert} login={login} setIsLoading={setIsLoading} />
                </div>
                {data&&(!isLoading) && <CustomYAxis shiftToLW={shiftToLW} lwPOS={lwPOS} dataOriginalLength={dataOriginalLength} data={data} yAxisData={yAxisData} yAxisLabel={yAxisLabel} row1={row1} rowN={rowN} setPlotWidth={setPlotWidth} type={1} />}
            </div>
            <div className={classes.bottomAxis}>
                {data&&(!isLoading) && <CustomXAxisPlot data={data} invert={invert} width={window.innerWidth - (2 * plotWidth)} />}
                {data&&(!isLoading) && <CustomXAxisImage yAxisLabel={yAxisLabel} column1={column1} columnN={columnN} width={window.innerWidth - (2 * plotWidth)} />}
            </div>
        </div>
    );
}

export default ProcessedExcel;