import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from 'recharts';
import classes from './DataGraph.module.css';

const DataGraph = (props) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const data = props.data;

    if (data) {
      let chartData = [];
      chartData = data.map((value, index) => ({ x: index+props.row1, y: value }));
      setChartData(chartData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.invert,props.lwStraightened]);
  
  return (
    <div className={classes.graph}>
      <ResponsiveContainer className={classes.plot} width={props.topBarHeight} height={window.innerWidth*0.4}>
        <LineChart data={chartData} margin={{ bottom: -5, left: -60 }}>
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis 
            dataKey="x" 
            tick={{ fontSize: 10, dx: 0 }}
            interval={50}
            domain={[props.row1,props.rowN-1]}
          />
          <YAxis
            type="number"
            tick={{ fontSize: 10, dy: -25 }}
            interval={50}
          />
          <ReferenceLine y={0} stroke="red" strokeWidth={1} label={{ position: 'insideTopLeft', fill: 'red' }} />
          <Line type="monotone" dataKey="y" stroke="#ffc929" strokeWidth={0.5} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DataGraph;
