import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Disclaimer from '../SignUp/Disclaimer/Disclaimer';
import api from '../../../Api';
import classes from './SignUp.module.css';

const SignUp = ({disclaimerAgreed,setDisclaimerAgreed}) => {
  const [warning, setWarning] = useState(false);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();


  

  const handleAgree = () => {
    setDisclaimerAgreed(true);
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!userId || !password || !confirmPassword) {
      setWarning(true);
      setMessage('Please fill all the fields!');
      return;
    }
    if (password !== confirmPassword) {
      setWarning(true);
      setMessage('Passwords do not match!');
      return;
    } else {
      setWarning(false);
    }
    
    const userInfo = {
      userId,
      password,
      name
    };

    try {
      const response = await api.post('/signup', userInfo);
      if (response.data.error === 'User already exists') {
        setWarning(true);
        setMessage('User already exists!');
        return;
      }

      if (!response.data.success) {
        setWarning(true);
        setMessage('Failed to authenticate.');
        return;
      }

      navigate('/login');

    } catch (error) {
      setWarning(true);
      setMessage('Internal Server Error');
      console.error('Error sending data to the backend:', error);
    }
  }, [userId, password, confirmPassword, name, navigate]);

  return (
    <>
      {!disclaimerAgreed && <div className={classes.disclaimer}>
        <Disclaimer onAgree={handleAgree} />
      </div>}
      <div className={classes.SignUpTitle}>
        <h1>Sign Up</h1>
      </div>
      <div className={classes.SignUpSubtitle}>
        <form className={classes.SignUpForm}>
          <div className={classes.SignUpInput}>
            <input type="text" placeholder="Enter your Name" value={name} required onChange={(e) => setName(e.target.value)} />
          </div>
          <div className={classes.SignUpInput}>
            <input type="email" placeholder="Enter your Id" value={userId} required onChange={(e) => setUserId(e.target.value)} />
          </div>
          <div className={classes.SignUpInput}>
            <input type="password" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </div>
          <div className={classes.SignUpInput}>
            <input type="password" placeholder="Confirm your password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
          </div>
          {warning && <div className={classes.warning}>
            <p>{message}</p>
          </div>}
          <div className={classes.SignUpButton}>
            <button type="submit" onClick={handleSubmit}>Create Account</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignUp;
