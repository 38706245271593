import classes from './CustomXAxisPlot.module.css';

const CustomXAxisPlot = (props) => {
    // console.log(props.data);
    const data=props.data;
    // Extract values from the data object
    var values;
    if (props.invert) {
        values = Object.values(data);
    } else {
        values = Object.values(data).flatMap((values) => values);
    }
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    // console.log(values);
    const ticksCount = Math.ceil(maxValue-minValue);
    const width = Math.ceil((window.innerHeight * 0.74) / ticksCount);

    const count = 5;
    const interval = Math.floor(ticksCount / count);
    const ticks = Array.from({ length: ticksCount }, (_, index) => minValue + index);
    // console.log('len ',ticks.length);

    return (
        <div className={classes.axis} style={{ width: `${(0.4 * props.width) + 32}px`, paddingLeft: '2rem', backgroundColor: 'rgb(7 83 2)' }}>
            {ticks.map((tick, index) => {
                if (index % interval === 0) {
                    return (
                        <div key={index} className={classes.tick} style={{ width: `${width}px` }}>
                            <div className={classes.dash}>
                                <div className={classes.bigDash}></div>
                            </div>
                            <p style={{marginRight:`${index===(ticks.length-(ticks.length%interval))?'1rem':null}`}}>{tick.toFixed(2)}</p>
                        </div>
                    );
                } else if (index % (Math.floor(interval / 4)) === 0) {
                    return (
                        <div key={index} className={classes.tick} style={{ width: `${width}px` }}>
                            <div className={classes.dash}>
                                <div className={classes.smallDash}></div>
                            </div>
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
}

export default CustomXAxisPlot;
