import { Link } from 'react-router-dom';
import classes from './QuestionSet.module.css';
import { useState } from 'react';


const QuestionSet = (props) => {
  const [jobNumber,setJobNumber] = useState(parseInt(props.questionResults?.jobNumber) +1);
  const handleContinue = async () => {
    props.setShowQuestions(false)
    props.setQuestionResults((prev) => {
      return { ...prev, jobNumber: String(jobNumber) }
    })
  }


  return (
    <div className={classes.questionsPage}>
      <div className={classes.questionSet}>
        <div className={classes.questions}>
          <h1>Information</h1>
          <div className={classes.question}>
            <p>1. Welder's Name</p>
            <input type="text" placeholder="Enter Name" value={props.questionResults?.name ? props.questionResults.name : null} onChange={e => props.setQuestionResults((prev) => {
              return { ...prev, name: e.target.value }
            })} />
          </div>
          <div className={classes.question}>
            <p>2. Date of Welding</p>
            <input type="date" />
          </div>
          <div className={classes.question}>
            <p>3. Job Number</p>
            <input type="text" placeholder="Enter Job Number" value={jobNumber } onChange={e => setJobNumber(e.target.value)} />
          </div>
          <div className={classes.question}>
            <p>4. Job Material</p>
            <input type="text" placeholder="Enter Job Material" value={props.questionResults?.jobMaterial } onChange={e => props.setQuestionResults((prev) => {
              return { ...prev, jobMaterial: e.target.value }
            })} />
          </div>
          <div className={classes.buttons}>
            <Link to="/">
              <button>
                Cancel
              </button>
            </Link>
            <button onClick={handleContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionSet;