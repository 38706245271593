import React from 'react';
import classes from './Login.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import api from '../../../Api';


const Login = React.memo(({ props }) => {
  const [choosed, setChoosed] = useState(0);
  const [warning, setWarning] = useState(null);
  const [userId, setUserId] = useState(null);
  const [password, setPassword] = useState(null);
  const navigate = useNavigate(null);


  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    if (!userId || !password) {
      setWarning('Please enter User-Id and Password');
      return;
    }
    if (choosed === 0) {
      setWarning('Please choose a mode!');
      return;
    }
    props.setUserId(userId);
    try {
      const res = await api.post('/login', {
        userId: userId,
        password: password,
        mode: choosed
      });

      const response = res.data;

      if (!response.success) {
        setWarning('Invalid User-Id or Password!');
        return;
      }

      props.setLogin(true);
      props.setSavedData({
        userId: userId,
        mode: choosed,
        scalingParam: response.savedScalingParam
      });
      props.setQuestionResults((prev) => ({
        ...prev,
        jobNumber: response.jobNumber || 0,
        name: response.name || '',
        userId: userId,
        mode: choosed === 1 ? 'Automatic' : 'Manual'
      }));
      props.setUserFiles(response.userFiles);
      props.setScalingParam(response.savedScalingParam);
      navigate('/mode');

    } catch (error) {
      setWarning(error.message);
    }
  }, [choosed, userId, password, props, navigate]);

  const handleClick = useCallback((opt) => {
    setChoosed(opt);
    props.setMode(opt);
  }, [props]);


  return (
    <>
      <div className={classes.loginTitle}>
        <h1>Login</h1>
      </div>
      <div className={classes.loginSubtitle}>
        <form className={classes.loginForm}>
          <div className={classes.loginInput}>
            <input type="email" placeholder="Enter your User-Id" required onChange={(e) => setUserId(e.target.value)} />
          </div>
          <div className={classes.loginInput}>
            <input type="password" placeholder="Enter your password" required onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div className={classes.mode}>
            <span style={choosed === 1 ? { backgroundColor: 'black', color: '#ffc929' } : null} onClick={() => handleClick(1)}>Automatic</span>
            <span style={choosed === 2 ? { backgroundColor: 'black', color: '#ffc929' } : null} onClick={() => handleClick(2)}>Manual</span>
          </div>
          {warning && <div className={classes.warning}>
            <p>{warning}</p>
          </div>}
          <div className={classes.loginButton}>
            <button type="submit" onClick={handleSubmit}>Sign In</button>
          </div>
          <div className={classes.signup}>
            Don't have an account? <Link to='/signup'>Sign Up</Link>
          </div>
        </form>
      </div>
    </>
  );
});

export default Login;