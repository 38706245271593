/* eslint-disable react-hooks/exhaustive-deps */
import classes from './ConditionalPlot.module.css';
import React, { useEffect, useRef, useState } from 'react';
import ContextMenu from './ContextMenu/ContextMenu';
import ContextMenuAnnotation from './ContextMenu/ContextMenuAnnotation';
import DefectDetail from './DefectDetail/DefectDetail';
import api from '../../Api';

const ConditionalPlot = (props) => {
  const imageRef = useRef(null);
  const [zoomRegion, setZoomRegion] = useState(null);
  const [dragVerticalLine, setDragVerticalLine] = useState(null);
  const [dragHorizontalLine, setDragHorizontalLine] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [data, setData] = useState([]);
  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const [contextMenuPositionAnnotation, setContextMenuPositionAnnotation] = useState(null);
  const [zoomFactorX, setZoomFactorX] = useState(1);
  const [zoomFactorY, setZoomFactorY] = useState(1);
  const [zoomedRegion, setZoomedRegion] = useState({
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
  });

  const [clickPos, setClickPos] = useState({ x: 0, y: 0 });
  const [contextMenuSize, setContextMenuSize] = useState({ width: 0, height: 0 });

  const [row1, setRow1] = useState(0);
  const [column1, setColumn1] = useState(0);
  const [rowN, setRowN] = useState(0);
  const [columnN, setColumnN] = useState(0);

  const [labelWidth, setLabelWidth] = useState(0);
  const [labelHeight, setLabelHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(props.plotWidth + 32);

  const [verticalLines, setVerticalLines] = useState({
    1: null,
    2: null,
    3: null,
  });

  const [horizontalLines, setHorizontalLines] = useState({
    1: null,
    2: null,
    3: null,
  });

  const [column, setColumn] = useState({
    1: null,
    2: null,
    3: null,
  })

  const [row, setRow] = useState({
    1: null,
    2: null,
    3: null,
  })


  const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const csvFileType='text/csv';

  const [annotationRectangles, setAnnotationRectangles] = useState([]);
  const [annotationRectanglesOriginal, setAnnotationRectanglesOriginal] = useState([]);
  const [isCreatingRectangle, setIsCreatingRectangle] = useState(false);
  const [currentRectangle, setCurrentRectangle] = useState(null);
  const [currentRectangleName, setCurrentRectangleName] = useState('');
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [featherFileName, setFeatherFileName] = useState(null);


  const color = { 1: 'red', 2: 'blue', 3: '#ffc929' };

  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);

  const plotRef = useRef(null);


  useEffect(() => {

    const readExcelFile = async () => {
      const selectedFile = props.file;
      var data = [];
      try {
        if (selectedFile) {
          if (!props.lwStraighten) {
            data = await sendDataToBackend(selectedFile);
          }
          else if (props.lwStraightened !== true) {
            data = await straightenLW(selectedFile);
          }
          props.setData(data[0]);
          props.setColumnN(data.length);
          setColumnN(data.length);
          props.setRowN(data[0].length);
          props.setDataOriginalLength(data[0].length);
          setRowN(data[0].length);
          props.setRowCol([data.length, data[0].length]);
        }
      } catch (error) {
        console.error('Error reading or sending the Excel file:', error);
      }
    };
    readExcelFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.file, props.lwStraighten]);

  const scalingParamProcess=async()=>{
    try{
      const content={
        scalingParam:props.scalingParam,
        userId:props.userId,
      }
      props.setScaleLoading(true);
      const responseData = await api.post('/processScalingParam', content);
      props.setScaleLoading(false);
      props.setProcessScalingParam(false);
      props.setYAxisLabel('mm');
      props.setYAxisData(responseData.data);
    }
    catch(error){
      console.error('Error sending scaling param to the backend:', error);
    }
  }

  useEffect(()=>{
    if(props.processScalingParam){
      scalingParamProcess();
    }
  },[props.processScalingParam])

  useEffect(() => {
    const newData = {
      featherFileName: featherFileName,
      annotationRectanglesOriginal: annotationRectanglesOriginal,
      lwPos: props.lwPos,
      lwStraightened: props.lwStraightened,
      clustInfo: props.clustInfo,
      scalingParam:props.scalingParam,
      yAxisData: props.yAxisData,
      yAxisLabel: props.yAxisLabel,
      annotationDimension:props.annotationDimension
    }
    props.setSavedData(newData); //saving the data to be used later 
  }, [annotationRectanglesOriginal, props.lwPos, featherFileName, props.lwStraightened, props.clustInfo,props.scalingParam,props.yAxisData,props.yAxisLabel]);

  useEffect(() => {
    props.setImage(capturedImage);
  }, [capturedImage]);

  useEffect(() => {
    setFeatherFileName(null);
  }, [props.file])
  useEffect(() => {
    props.setFeatherFileName(featherFileName);
  }, [featherFileName]);

  useEffect(() => {
    setWindowWidth(props.plotWidth + 32);
  }, [props.plotWidth]);



  const sendDataToBackend = async (data) => {
    setIsLoading(true);
    // Step 1: Prepare FormData object
    const formData = new FormData();


    try {
      let res;
      if(data.originalFileName===undefined){
        formData.append('file', data); // Append the file to FormData

        // Append other fields to FormData
        formData.append('fileType', data?.type === excelFileType ? 'excel' : data?.type === csvFileType ? 'csv' : 'feather');
        formData.append('invert', props.invert);
        formData.append('featherFileName', featherFileName);
        formData.append('mode', props.mode);
        formData.append('scalingParam', props.scalingParam);
        formData.append('userId',props.userId);
        formData.append('fileName',data.name);
        res= await api.post('/processData', formData);

      }
      else{
        formData.append('file', JSON.stringify(data)); // Append the file to FormData
        res= await api.post('/processSavedFile', formData);
      }
      const jobNumber=String(props.questionResults?.jobNumber);
      await api.post('/saveJobNumber', {jobNumber:jobNumber});
      const responseData = res.data;
      props.setInvert(responseData.invert);
      console.log('Response from the backend:', responseData);
      props.setDataFetched(true);
      var transposedData;
      if (props.mode === 1 && (props.file?.type === excelFileType||props.file?.type===csvFileType)) {
        transposedData = responseData.invert ? transpose(responseData.updatedMatrix) : responseData.updatedMatrix;
      } else {
        transposedData = responseData.invert ? responseData.updatedMatrix : transpose(responseData.updatedMatrix);
      }
      if (props.mode === 1) {
        props.setLWStraightened(true);
      } else {
        props.setLWStraightened(responseData.lwStraightened);
      }
      props.setUserFiles(responseData.userFiles);
      props.setClustInfo(responseData.clustInfo);
      responseData?.annotationRectanglesOriginal?.map((rectangle) => {
        rectangle.startX = (window.innerWidth * parseFloat(rectangle.startX / rectangle.windowWidth));
        rectangle.startY = (window.innerHeight * parseFloat(rectangle.startY / rectangle.windowHeight));
        rectangle.width = (window.innerWidth * parseFloat(rectangle.width / rectangle.windowWidth));
        rectangle.height = (window.innerHeight * parseFloat(rectangle.height / rectangle.windowHeight));
        return rectangle;
      });
      setAnnotationRectangles(responseData.annotationRectanglesOriginal || []);
      setAnnotationRectanglesOriginal(responseData.annotationRectanglesOriginal || []);
      setData(transposedData);
      setFeatherFileName(responseData.featherFileName);
      setCapturedImage(responseData.imageData);
      props.setLWPOS(responseData.lwPos);
      setIsLoading(false);
      props.setYAxisData(responseData.yAxisData?JSON.parse(responseData.yAxisData):null);
      props.setYAxisLabel(responseData.yAxisLabel?responseData.yAxisLabel:"px");
      props.setAnnotationDimension(responseData.annotationDimension?JSON.parse(responseData.annotationDimension):null);
      return transposedData;
    } catch (error) {
      console.error('Error sending data to the backend:', error);
      setIsLoading(false);
    }
    return [];
  };

  function transpose(matrix) {
    return matrix[0].map((_, columnIndex) => matrix.map(row => row[columnIndex]));
  }


  const straightenLW = async (data) => {
    setIsLoading(true);
    // Step 1: Prepare FormData object
    const formData = new FormData();
    formData.append('file', data); // Append the file to FormData

    // Append other fields to FormData
    formData.append('fileType', data?.type === excelFileType ? 'excel' : data?.type === csvFileType ? 'csv' : 'feather');
    formData.append('invert', props.invert);
    formData.append('featherFileName', featherFileName);
    formData.append('mode', props.mode);
    formData.append('userId',props.userId);

    try {
      // Send data to the main process via IPC
      const res = await api.post('lwStraighten', formData);
      const response=res.data;
      // Handle the response from the main process
      console.log('Response from the main process:', response);
      props.setLWStraightened(true);
      setCapturedImage(response.imageData);
      const transposedData = response.invert ? transpose(response.updatedMatrix) : response.updatedMatrix;
      setData(transposedData);
      props.setLWPOS(response.lwPos);
      setIsLoading(false);
      setAnnotationRectanglesOriginal([]);
      setAnnotationRectangles([]);
      props.setAnnotationDimension([]);
      return transposedData;
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
      // Handle errors here
      return [];
    }
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    const x = e.clientX;
    const y = e.clientY;
    setContextMenuPosition(null);
    setContextMenuPositionAnnotation(null);


    setClickPos({ x: x, y: y });
    const annotationBoxIndex = annotationRectangles.findIndex((rect) => {
      const rectLeft = Math.min(rect.startX, rect.startX + rect.width);
      const rectRight = Math.max(rect.startX, rect.startX + rect.width);
      const rectTop = Math.min(rect.startY, rect.startY + rect.height);
      const rectBottom = Math.max(rect.startY, rect.startY + rect.height);
      return x >= rectLeft && x <= rectRight && y >= rectTop && y <= rectBottom;
    });
    if (annotationBoxIndex !== -1) {
      handleAnnotationContextMenu(e, annotationBoxIndex);
      setSelectedAnnotation(annotationBoxIndex);
      return;
    }
    if (contextMenuSize.width + x > windowWidth) {
      if (contextMenuSize.height + y > window.innerHeight) {
        setContextMenuPosition({ x: x - contextMenuSize.width, y: y - contextMenuSize.height });
        return;
      }
      setContextMenuPosition({ x: x - contextMenuSize.width, y: y });
      return;
    }
    if (contextMenuSize.height + y > window.innerHeight) {
      if (contextMenuSize.width + x > windowWidth) {
        setContextMenuPosition({ x: x - contextMenuSize.width, y: y - contextMenuSize.height });
        return;
      }
      setContextMenuPosition({ x: x, y: y - contextMenuSize.height });
      return;
    }
    setContextMenuPosition({ x: x, y: y });
  };

  const handleClick = (e) => {
    props.setDefectDetail(null);
    setContextMenuPosition(null);
    setContextMenuPositionAnnotation(null);
    if (e.button === 2) {
      props.setHandleZoom(0);
      props.setIsAnnote(0);
    }

    if(props.mode===1&&(!props.isAnnote)&&((props.handleZoom === 1 && props.isZoomed)||(!props.handleZoom)||(props.handleZoom===-1))){
      handleHover(e)
    }
  };


  const handleAddLine = (pos, option) => {
    const width = plotRef.current.offsetWidth;
    const height = plotRef.current.offsetHeight;
    const leftPos = plotRef.current.offsetLeft;
    const topPos = plotRef.current.offsetTop;
    const x = clickPos.x;
    const y = clickPos.y;


    const eachColumnWidth = width / (columnN - column1);
    const eachRowWidth = height / (rowN - row1);
    const currentRow = Math.min(row1 + Math.round(((y - topPos) / eachRowWidth)), (rowN - 1));
    const currentColumn = Math.min(column1 + Math.round(((x - leftPos) / eachColumnWidth)), (columnN - 1));



    if (option === 1) {
      props.setData(data[currentColumn].slice(row1, rowN));
    }
    setColumn((prevLines) => Object.fromEntries(
      Object.entries(prevLines).map(([key, value]) => {
        if (parseInt(key) === option) {
          return [key, currentColumn];
        }
        return [key, value];
      })
    ));
    setRow((prevLines) => Object.fromEntries(
      Object.entries(prevLines).map(([key, value]) => {
        if (parseInt(key) === option) {
          return [key, currentRow];
        }
        return [key, value];
      })
    ));
    setVerticalLines((prevLines) => Object.fromEntries(
      Object.entries(prevLines).map(([key, value]) => {
        if (parseInt(key) === option) {
          return [key, x];
        }
        return [key, value];
      })
    ));
    if (option !== 1) {
      setHorizontalLines((prevLines) => Object.fromEntries(
        Object.entries(prevLines).map(([key, value]) => {
          if (parseInt(key) === option) {
            return [key, y];
          }
          return [key, value];
        })
      ));
    }
    setContextMenuPosition(null);
  };

  const handleMouseDown = (e, option) => {
    handleClick(e);
    if (e.button === 2) {
      handleContextMenu(e);
      return;
    }
    setDragHorizontalLine(parseInt(option));
    setDragVerticalLine(parseInt(option));
    setIsDragging(true);
    setDragStartY(e.clientY);
    setDragStartX(e.clientX);
    if (props.handleZoom === 1 && (!props.isZoomed)) {
      setZoomRegion({ startX: e.clientX, startY: e.clientY, endX: e.clientX, endY: e.clientY });
      setZoomedRegion({ startX: e.clientX, startY: e.clientY, endX: e.clientX, endY: e.clientY });
      setHorizontalLines((prev) => Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, null])
      ))
      setVerticalLines((prev) => Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, null])
      ))
      setRow((prev) => Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, null])
      ))
      setColumn((prev) => Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, null])
      ))
    }
    else if (props.handleZoom === -1) {
      setRow1(0);
      props.setRow1(0);
      setColumn1(0);
      props.setColumn1(0);
      props.setData(data[0]);
      props.setColumnN(data.length);
      setColumnN(data.length);
      props.setRowN(data[0].length);
      setRowN(data[0].length);
      props.setIsZoomed(false);
      setZoomRegion(null);
    }
  };



  const handleZoom = () => {
    if (zoomRegion && (!props.isZoomed) && (!isLoading)) {
      // Implement zoom action based on zoomRegion coordinates
      // For example, update the displayed image or fetch a higher-resolution image
      const selectedWidth = Math.abs(zoomRegion.endX - zoomRegion.startX);
      const selectedHeight = Math.abs(zoomRegion.endY - zoomRegion.startY);
      const width = plotRef.current.offsetWidth;
      const height = plotRef.current.offsetHeight;

      // Calculate the zoom factor
      const zoomFactorX = width / selectedWidth;
      const zoomFactorY = height / selectedHeight;
      setZoomFactorX(zoomFactorX);
      setZoomFactorY(zoomFactorY);

      // Calculate the new dimensions for the image
      const newImageWidth = parseFloat(width * zoomFactorX);
      const newImageHeight = parseFloat(height * zoomFactorY);


      // Calculate the position for the zoomed image
      const newLeft = parseFloat(Math.min(zoomRegion.startX, zoomRegion.endX) - plotRef.current.offsetLeft);
      const newTop = parseFloat(Math.min(zoomRegion.startY, zoomRegion.endY) - plotRef.current.offsetTop);
      const imageElement = imageRef.current;

      setAnnotationRectangles((prev) => prev.map((rect) => {
        const newRect = {
          startX: (parseFloat(Math.min(rect.startX, rect.startX + rect.width) - zoomRegion.startX) * (newLeft * zoomFactorX) / (zoomRegion.startX - plotRef.current.offsetLeft)) + plotRef.current.offsetLeft,
          startY: (parseFloat(Math.min(rect.startY, rect.startY + rect.height) - zoomRegion.startY) * (newTop * zoomFactorY) / (zoomRegion.startY - plotRef.current.offsetTop)) + plotRef.current.offsetTop,
          width: parseFloat(rect.width * zoomFactorX),
          height: parseFloat(rect.height * zoomFactorY),
          name: rect.name,
        };
        return newRect;
      }));

      if (imageElement) {
        imageElement.style.width = `${newImageWidth}px`;
        imageElement.style.height = `${newImageHeight}px`;
        imageElement.style.left = `-${parseFloat(newLeft * zoomFactorX)}px`;
        imageElement.style.top = `-${parseFloat(newTop * zoomFactorY)}px`;
      }
      // Extract data within the zoomed region and send it through props
      const eachColumnWidth = width / data.length;
      const eachRowWidth = height / data[0].length;

      const startRow = Math.floor((Math.min(zoomRegion.startY, zoomRegion.endY) - plotRef.current.offsetTop) / eachRowWidth);
      const endRow = Math.floor((Math.max(zoomRegion.startY, zoomRegion.endY) - plotRef.current.offsetTop) / eachRowWidth);

      const startColumn = Math.floor((Math.min(zoomRegion.startX, zoomRegion.endX) - plotRef.current.offsetLeft) / eachColumnWidth);
      const endColumn = Math.floor((Math.max(zoomRegion.startX, zoomRegion.endX) - plotRef.current.offsetLeft) / eachColumnWidth);


      props.setData(data[column1].slice(startRow, endRow));


      props.setRow1(startRow);
      setRow1(startRow);
      props.setRowN(endRow);
      setRowN(endRow);
      props.setColumn1(startColumn);
      setColumn1(startColumn);
      props.setColumnN(endColumn);
      setColumnN(endColumn);
      setZoomRegion(null); // Reset zoomRegion after processing
      props.setIsZoomed(true);
    }
  };

  useEffect(() => {
    if (props.handleZoom === -1) {
      const imageElement = imageRef.current;
      if (imageElement) {
        // Adjust these values based on your original dimensions and position
        const originalWidth = '100%';
        const originalHeight = '100%';
        const originalLeft = 0;
        const originalTop = 0;

        imageElement.style.width = originalWidth;
        imageElement.style.height = originalHeight;
        imageElement.style.left = `${originalLeft}px`;
        imageElement.style.top = `${originalTop}px`;
      }
      props.setRow1(0);
      setRow1(0);
      props.setColumn1(0);
      setColumn1(0);

      props.setData(data[0]);
      props.setColumnN(data.length);
      setColumnN(data.length);
      props.setRowN(data[0].length);
      setRowN(data[0].length);
      props.setIsZoomed(false);
      setZoomRegion(null);
    }

    const handleMouseMove = (e) => {
      if (isDragging && dragHorizontalLine) {
        const offsetX = e.clientX - dragStartX;
        const offsetY = e.clientY - dragStartY;

        const width = plotRef.current.offsetWidth;
        const height = plotRef.current.offsetHeight;
        const leftPos = plotRef.current.offsetLeft;
        const topPos = plotRef.current.offsetTop;

        const newLeft = Math.min(Math.max(leftPos, verticalLines[dragVerticalLine] + offsetX), windowWidth);
        const newDown = Math.min(Math.max(topPos, horizontalLines[dragHorizontalLine] + offsetY), window.innerHeight);

        setVerticalLines((prevLines) => Object.fromEntries(
          Object.entries(prevLines).map(([key, value]) => {
            if (parseInt(key) === dragVerticalLine) {
              return [key, newLeft];
            }
            return [key, value];
          })
        ));
        if (dragHorizontalLine !== 1) {
          setHorizontalLines((prevLines) => Object.fromEntries(
            Object.entries(prevLines).map(([key, value]) => {
              if (parseInt(key) === dragHorizontalLine) {
                return [key, newDown];
              }
              return [key, value];
            })
          ));
        }
        const eachColumnWidth = width / (columnN - column1);
        const eachRowWidth = height / (rowN - row1);
        const currentRow = Math.min(row1 + Math.round(((newDown - topPos) / eachRowWidth)), (rowN - 1));
        const currentColumn = Math.min(column1 + Math.round(((newLeft - leftPos) / eachColumnWidth)), (columnN - 1));

        setColumn((prevLines) => Object.fromEntries(
          Object.entries(prevLines).map(([key, value]) => {
            if (parseInt(key) === dragVerticalLine) {
              return [key, currentColumn];
            }
            return [key, value];
          })
        ));

        setRow((prevLines) => Object.fromEntries(
          Object.entries(prevLines).map(([key, value]) => {
            if (parseInt(key) === dragHorizontalLine) {
              return [key, currentRow];
            }
            return [key, value];
          })
        ));
        if (dragVerticalLine === 1) {
          props.setData(data[currentColumn].slice(row1, rowN));

        }

        setDragStartX(e.clientX);
        setDragStartY(e.clientY);

      }
      else if (isDragging && props.handleZoom === 1 && (!props.isZoomed)) {

        setZoomRegion((prevZoomRegion) => ({
          ...prevZoomRegion,
          endX: Math.max(Math.min(e.clientX, windowWidth), 0),
          endY: Math.max(Math.min(e.clientY, window.innerHeight), 0),
        }));
        setZoomedRegion((prevZoomedRegion) => ({
          ...prevZoomedRegion,
          endX: Math.max(Math.min(e.clientX, windowWidth), 0),
          endY: Math.max(Math.min(e.clientY, window.innerHeight), 0),
        }));
      }
    };

    const handleMouseUp = () => {
      if (props.handleZoom === 1 && (!props.isZoomed)) {
        handleZoom();
      }
      setIsDragging(false);
    };


    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    document.addEventListener('touchmove', handleMouseMove);
    document.addEventListener('touchend', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);

      document.removeEventListener('touchmove', handleMouseMove);
      document.removeEventListener('touchend', handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleMouseDown, props.handleZoom]);

  useEffect(() => {
    if (props.handleZoom === -1) {
      setHorizontalLines((prev) => Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, null])
      ))
      setVerticalLines((prev) => Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, null])
      ))
      setRow((prev) => Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, null])
      ))
      setColumn((prev) => Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, null])
      ))
      setAnnotationRectangles(annotationRectanglesOriginal);
      setZoomFactorX(1);
      setZoomFactorY(1);
      setZoomedRegion({
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.handleZoom]);

  const rowLabelElement = document.querySelector(`.${classes.rowLabel}`);
  const columnLabelElement = document.querySelector(`.${classes.columnLabel}`);

  useEffect(() => {
    if (rowLabelElement) {
      setLabelHeight(rowLabelElement.getBoundingClientRect().height);
    }
    if (columnLabelElement) {
      setLabelWidth(columnLabelElement.getBoundingClientRect().width);
    }
  }, [rowLabelElement, columnLabelElement])

  useEffect(() => {
    props.setRow(row);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  useEffect(() => {
    props.setColumn(column);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column]);

  const handleStartRectangle = (e) => {
    e.preventDefault();
    setIsCreatingRectangle(true);
    const startPosition = {
      x: e.clientX,
      y: e.clientY,
    };
    setCurrentRectangle({
      startX: startPosition.x,
      startY: startPosition.y,
      width: 0,
      height: 0,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
    setCurrentRectangleName('');
  };

  const handleDrawRectangle = (e) => {
    e.preventDefault();
    if (isCreatingRectangle) {
      // Update the rectangle position and size in the state
      setCurrentRectangle({
        ...currentRectangle,
        width: (e.clientX - currentRectangle.startX),
        height: (e.clientY - currentRectangle.startY),
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      });
    }
  };

  const handleEndRectangle = () => {
    setIsCreatingRectangle(false);
    // Update the rectangle list in the state
  };



  const handleSubmitRectangleName = () => {
    if (isEditing) {
      const updatedAnnotations = annotationRectangles.map((rect, index) => {
        if (index === selectedAnnotation) {
          return { ...rect, name: currentRectangleName };
        }
        return rect;
      });
      const updatedAnnotationsDimensions=props.annotationDimension.map((rect,index)=>{
        if (index === selectedAnnotation) {
          return { ...rect, name: currentRectangleName };
        }
        return rect;
      })
      props.setAnnotationDimension(updatedAnnotationsDimensions);
      setAnnotationRectangles(updatedAnnotations);
      setAnnotationRectanglesOriginal(updatedAnnotations);
      setCurrentRectangleName('');
      setCurrentRectangle(null);
      setIsEditing(false);
      setSelectedAnnotation(null);
      return;
    }

    const imageElement = imageRef.current;
    const currentAdjustedRectangle = {
      startX: (parseFloat(Math.min(currentRectangle.startX, currentRectangle.startX + currentRectangle.width) - plotRef.current.offsetLeft) * (zoomedRegion.startX - plotRef.current.offsetLeft) / Math.abs(imageElement.offsetLeft)) + zoomedRegion.startX,
      startY: (parseFloat(Math.min(currentRectangle.startY, currentRectangle.startY + currentRectangle.height) - plotRef.current.offsetTop) * (zoomedRegion.startY - plotRef.current.offsetTop) / Math.abs(imageElement.offsetTop)) + zoomedRegion.startY,
      width: parseFloat(currentRectangle.width / zoomFactorX),
      height: parseFloat(currentRectangle.height / zoomFactorY),
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }
    const savedAnnotationDimension=props.annotationDimension|| [];
    const updatedAnnotationsDimensions=[
      ...savedAnnotationDimension,
      { ...props.currentAnnotationDimension, name: currentRectangleName },
    ]

    const updatedAnnotations = [
      ...annotationRectangles,
      { ...currentRectangle, name: currentRectangleName },
    ];

    const newUpdatedAnnotations = [
      ...annotationRectanglesOriginal,
      { ...currentAdjustedRectangle, name: currentRectangleName },
    ];
    props.setAnnotationDimension(updatedAnnotationsDimensions);
    setAnnotationRectangles(updatedAnnotations);
    setAnnotationRectanglesOriginal(newUpdatedAnnotations);
    setCurrentRectangleName('');
    setCurrentRectangle(null);
    setSelectedAnnotation(null);
  }





  const handleAnnotationContextMenu = (e, index) => {
    e.preventDefault();
    const x = e.clientX - plotRef.current.offsetLeft;
    const y = e.clientY - plotRef.current.offsetTop;
    setContextMenuPositionAnnotation({ x, y });
  };

  const handleEdit = (index) => {
    // Implement edit logic here
    console.log('Edit annotation at index:', selectedAnnotation);
    setCurrentRectangle(annotationRectangles[index]);
    setIsEditing(true);
    setContextMenuPositionAnnotation(null);
  };

  const handleDelete = () => {
    // Implement delete logic here
    console.log('Delete annotation at index:', selectedAnnotation);
    const newAnnotationRectangles = annotationRectangles.filter((_, i) => i !== selectedAnnotation);
    const newUpdatedAnnotations = annotationRectanglesOriginal.filter((_, i) => i !== selectedAnnotation);
    const newUpdatedAnnotationsDimensions=props.annotationDimension.filter((_, i) => i !== selectedAnnotation);
    props.setAnnotationDimension(newUpdatedAnnotationsDimensions);
    setAnnotationRectangles(newAnnotationRectangles);
    setAnnotationRectanglesOriginal(newUpdatedAnnotations);
    setContextMenuPositionAnnotation(null);
    setSelectedAnnotation(null);
  };


  const handleHover = (e) => {
    const x = e.clientX;
    const y = e.clientY;
    const width = plotRef.current.offsetWidth;
    const height = plotRef.current.offsetHeight;
    const leftPos = plotRef.current.offsetLeft;
    const topPos = plotRef.current.offsetTop;
    const eachColumnWidth = width / (columnN - column1);
    const eachRowWidth = height / (rowN - row1);
    const currentRow = Math.min(row1 + Math.round(((y - topPos) / eachRowWidth)), (rowN - 1));
    const currentColumn = Math.min(column1 + Math.round(((x - leftPos) / eachColumnWidth)), (columnN - 1));

    props.clustInfo&&props.clustInfo.length&&props.clustInfo.map((clust)=>{
      const clustStartingRow=clust[1]+props.lwPOS;
      const clustEndingRow=clust[1]+props.lwPOS+clust[2];
      const clustStartingColumn=clust[4];
      const clustEndingColumn=clust[4]+clust[3];
      if(((clustStartingRow<=currentRow)&&(currentRow<=clustEndingRow))&&((currentColumn>=clustStartingColumn)&&(currentColumn<=clustEndingColumn))){
        props.setDefectPos({x:x,y:y});
        props.setDefectDetail(clust);
      }
      return clust;
    })
  }

  useEffect(() => {
    props.setIsLoading(isLoading);
    setVerticalLines({
      1: null,
      2: null,
      3: null,
    });
    setHorizontalLines({
      1: null,
      2: null,
      3: null,
    });
  }, [isLoading]);

  useEffect(() => {
    if (plotRef?.current?.offsetWidth && plotRef?.current?.offsetHeight) {
      props.setPlotDimension([plotRef.current.offsetWidth, plotRef.current.offsetHeight, plotRef.current.offsetLeft, plotRef.current.offsetTop]);
    }
  }, [plotRef.current?.offsetWidth, plotRef.current?.offsetHeight, plotRef.current?.offsetLeft, plotRef.current?.offsetTop])


  return (
    <div className={classes.plot} ref={plotRef}>
      {(!isLoading) && capturedImage ? (
        <img
          className={classes.image}
          src={`data:image/png;base64,${capturedImage}`}
          alt="Generated Plot"
          ref={imageRef}
          onContextMenu={handleContextMenu}
          onClick={(e)=>handleClick(e)}
          onMouseDown={props.handleZoom === 1 ? handleMouseDown : props.isAnnote ? handleStartRectangle : null}
          onMouseMove={props.isAnnote ? handleDrawRectangle : null}
          onMouseUp={props.isAnnote ? handleEndRectangle : null}
        />
      ) : (!isLoading) && (capturedImage !== null) && (
        <div>No images to display</div>
      )}

      {props.defectDetail?<DefectDetail dataOriginalLength={props.dataOriginalLength} yAxisData={props.yAxisData} yAxisLabel={props.yAxisLabel} plotWidth={plotRef.current?.offsetWidth} plotHeight={plotRef.current?.offsetHeight} plotLeft={plotRef?.current?.offsetLeft} plotTop={plotRef?.current?.offsetTop}  defectPos={props.defectPos} defectDetail={props.defectDetail} />:null}

      {(!isLoading) && (capturedImage) && currentRectangle ? (
        <div
          className={classes.annotationRectangle}
          style={{
            left: (Math.min(currentRectangle.startX, currentRectangle.startX + currentRectangle.width) - (plotRef.current.offsetLeft)),
            top: (Math.min(currentRectangle.startY, currentRectangle.startY + currentRectangle.height) - (plotRef.current.offsetTop)),
            width: Math.abs(currentRectangle.width),
            height: Math.abs(currentRectangle.height),
          }}
        />
      ) : null}

      {(!isLoading) && (capturedImage) && currentRectangle ? (
        <div className={classes.annotationNameInput}>
          <input
            type="text"
            value={currentRectangleName}
            onChange={(e) => {
              setCurrentRectangleName(e.target.value);
            }}
            placeholder="Enter name..."
          />
          <button onClick={handleSubmitRectangleName}>Create Annotation</button>
        </div>
      ) : null}

      {(!isLoading) && (capturedImage) && annotationRectangles?.length && annotationRectangles.map((rectangle, index) => rectangle ? (
        <div
          key={`rectangle-${index}`}
          className={classes.annotationRectangle}
          style={{
            left: (Math.min(rectangle.startX, rectangle.startX + rectangle.width) - (plotRef.current.offsetLeft)),
            top: (Math.min(rectangle.startY, rectangle.startY + rectangle.height) - (plotRef.current.offsetTop)),
            width: Math.abs(rectangle.width),
            height: Math.abs(rectangle.height),
          }}
        >
          <div className={classes.rectangleName}>{rectangle.name}</div>
        </div>
      ) : null)}

      {selectedAnnotation !== null && contextMenuPositionAnnotation !== null ? <ContextMenuAnnotation
        position={{ x: contextMenuPositionAnnotation.x, y: contextMenuPositionAnnotation.y }}
        handleEdit={() => handleEdit(selectedAnnotation)}
        handleDelete={() => handleDelete(selectedAnnotation)}
      /> : null}

      {zoomRegion ? (
        <div
          className={classes.zoomRegion}
          style={{
            left: (Math.min(zoomRegion.startX, zoomRegion.endX) - (plotRef.current.offsetLeft)),
            top: (Math.min(zoomRegion.startY, zoomRegion.endY) - (plotRef.current.offsetTop)),
            width: Math.abs(zoomRegion.endX - zoomRegion.startX),
            height: Math.abs(zoomRegion.endY - zoomRegion.startY),
          }}
        />
      ) : null}

      {contextMenuPosition ? <ContextMenu position={contextMenuPosition} setContextMenuPosition={setContextMenuPosition} onAddLine={handleAddLine} setContextMenuSize={setContextMenuSize} verticalLines={verticalLines} setVerticalLines={setVerticalLines} setHorizontalLines={setHorizontalLines} setRow={setRow} setColumn={setColumn} mode={props.mode} /> : null}

      {Object.entries(verticalLines).map(([option, x]) => x ? (
        <div className={classes.verticalGroup} key={`vertical-line-${option}`}>
          <div
            className={classes.verticalLine}
            style={{ left: Math.min(x, windowWidth) - plotRef.current.offsetLeft, borderColor: color[option] }}
            onMouseDown={(e) => handleMouseDown(e, option)}
            onTouchStart={(e) => handleMouseDown(e, option)}
          />
          {option === '1' ?
            <p
              className={classes.columnLabel}
              style={{ left: Math.min(x, window.innerWidth - labelWidth) - plotRef.current.offsetLeft, color: color[option] }}
            >
              {column[option]+" "+props.yAxisLabel}
            </p> : null}
        </div>
      ) : null)}

      {Object.entries(horizontalLines).map(([option, y]) => y ? (
        <div className={classes.horizontalGroup} key={`horizontal-line-${option}`}>
          <div
            className={classes.horizontalLine}
            style={{ top: y - plotRef.current.offsetTop, height: `${plotRef.current.offsetWidth}`, borderColor: color[option] }}
            onMouseDown={(e) => handleMouseDown(e, option)}
            onTouchStart={(e) => handleMouseDown(e, option)}
          />
          <svg className={classes.parabola} style={{ top: Math.min(y, window.innerHeight) - plotRef.current.offsetTop - window.innerHeight * 0.105, left: Math.min(verticalLines[option], windowWidth) - plotRef.current.offsetLeft - props.plotWidth * 0.09, pointerEvents: 'none', }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 800">
            <path d="M0 800 Q200 -200 400 800" fill="none" stroke={color[option]} strokeWidth="5" />
          </svg>
        </div>
      ) : null)}

    </div>
  );
};

export default ConditionalPlot;
