import classes from './ContextMenuAnnotation.module.css';

const ContextMenuAnnotation = ({handleDelete,handleEdit,position}) => {
      

    return (
        <div className={classes.contextMenu} style={{ left: position.x, top: position.y }}>
            <ul>
                <li onClick={handleEdit}>
                    Edit
                </li>
                <li onClick={handleDelete}>
                    Delete
                </li>
            </ul>
        </div>
    );
}

export default ContextMenuAnnotation;