import classes from './Invert.module.css';

const Invert = (props) => {
    return (
        <div className={classes.invertOption}>
            <div className={classes.heading}>
                Do you want to invert the Matirx?
            </div>
            <div className={classes.buttons}>
                <button onClick={() => {
                    props.setInvert(true);
                    props.setShow(false);
                }}>Yes</button>
                <button onClick={() => {
                    props.setInvert(false);
                    props.setShow(false);
                }}>No</button>
            </div>
        </div>
    );
}

export default Invert;