import React, { useEffect, useState } from 'react';
import { Link, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import classes from './LandingPage.module.css';
import logo from '../../assets/logo/image7.png';
import glareImgTop from '../../assets/background/image14.png';
import glareImgBottom from '../../assets/background/image15.png';
import appLogo from '../../assets/logo/logo.png';
import Login from './Login/Login';
import SignUp from './SignUp/SignUp';
import MainLanding from './MainLanding/MainLanding';
// import pdfFile from '../../assets/docs/Brochure_CoEAMT_2.pdf';
import manual from '../../assets/docs/manual.pdf';
import bgImg from '../../assets/background/background.jpg';

const LandingPage = React.memo(({ props }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [disclaimerAgreed, setDisclaimerAgreed] = useState(false);

    const openPopup = (opt) => {
        setShowPopup(opt);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        const agreed = localStorage.getItem('disclaimerAgreed');
        if (agreed) {
            setDisclaimerAgreed(true);
        }
    }, []);

    return (
        <div className={classes.landing}>
            <img className={classes.bgImg} src={bgImg} alt='background' loading='lazy' />
            <div className={classes.appLogo}>
                <img src={appLogo} alt='appLogo' loading='lazy' />
            </div>
            <img src={glareImgTop} alt='glareImgTop' className={classes.glareImgTop} loading='lazy' />
            <img src={glareImgBottom} alt='glareImgBottom' className={classes.glareImgBottom} loading='lazy' />
            <div className={classes.navBar}>
                <Link to="/login"><button>Login</button></Link>
                <a href='https://www.coeamt.com/' target='blank'><button>About us</button></a>
                <button onClick={() => openPopup(2)}>Documentation</button>
                <Link to='/contactus'><button>Contact us</button></Link>
            </div>
            <div className={classes.landingBox}>
                <Routes>
                    <Route path="*" element={<MainLanding />} />
                    <Route path="/login" element={<Login props={props} />} />
                    <Route path="/signup" element={<SignUp disclaimerAgreed={disclaimerAgreed} setDisclaimerAgreed={setDisclaimerAgreed}/>} />
                </Routes>
                <span className={classes.note}>
                    *This is the trial version, model is still in the development stage, results may not be accurate needs manual verification
                </span>
                <div className={classes.landingFooter}>
                    <span className={classes.centreName}>
                        <img src={logo} alt='logo' />
                        <div className={classes.developedByText}>
                            <p style={{ color: "white", paddingBottom: "1rem" }}><u>Developed by:</u></p>
                            <p>CENTRE OF EXCELLENCE IN ADVANCED MANUFACTURING TECHNOLOGY</p>
                            <p>INDIAN INSTITUTE OF TECHNOLOGY KHARAGPUR</p>
                        </div>
                    </span>
                </div>
            </div>
            {showPopup === 2 && (
                <div className={classes.popup}>
                    <div className={classes.popupContent}>
                        <button onClick={closePopup}><i className="bi bi-x-circle-fill"></i></button>
                        <embed src={manual} type="application/pdf" width="100%" height="500px" />
                    </div>
                </div>
            )}
        </div>
    );
});

export default LandingPage;
