import classes from './CustomXAxisImage.module.css';

const CustomXAxisImage = (props) => {
    const ticksCount = props.columnN - props.column1; // Adjust the number of ticks as needed
    const width = Math.ceil((window.innerHeight * 0.74) / ticksCount);
    // console.log(height);
    const count = 5;
    const interval = Math.floor((props.columnN - props.column1) / count);
    // console.log(interval);
    const ticks = Array.from({ length: ticksCount }, (_, index) => index+props.column1 + 1);


    return (
        <div className={classes.axis} style={{width:`${(0.6*props.width)+32}px`,paddingRight:'2rem',backgroundColor:'rgb(214 0 0)'}}>
            <p className={classes.axisDataType}>{props.yAxisLabel}</p>
            {ticks.map((tick,index) => {
                if (((tick-props.column1 - 1) % interval === 0)) {
                    return (
                        <div key={tick} className={classes.tick} style={{ width: `${width}px` }}>
                            <div className={classes.dash}>
                                <div className={classes.bigDash}></div>
                            </div>
                            <p style={{marginLeft:`${index===0?'100%':index===(ticksCount-1)?'-100%':null}`}}>{tick}</p>
                        </div>
                    );
                }
                else if (((tick-props.column1 - 1) % (Math.floor(interval / 4)) === 0)) {
                    return (
                        <div key={tick} className={classes.tick} style={{ width: `${width}px` }}>
                            <div className={classes.dash}>
                                <div className={classes.smallDash}></div>
                            </div>
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
}

export default CustomXAxisImage;
